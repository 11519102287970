import React from 'react'
// import Gallery02 from '../../assets/images/gallery/about-2.jpg'
// import gallery04 from '../../assets/images/gallery/about-4.png'


function Intro() {
  return (
    <>
        <section className="about-section">
            <div className="container">
                <div className="row justify-content-between">
                <div className="col-lg-6">
                    <div className="about-image">
                    <div className="about-image-inner img-one">
                        {/* <img src={Gallery01} className="img-fluid" alt="img-2" /> */}
                        {/* <div className="sign-text">Kevin Martin</div> */}
                        
                        {/* <div className="about-image-caption">
                        <div className="about-image-caption-inner">
                            <span className="about-caption-number">18</span>
                            <span className="about-caption-text"> Years of <br /> experience </span>
                        </div>
                        
                        </div> */}
                        
                    </div>
                    
                    <div className="about-image-inner img-two">
                        {/* <img src={Shapes03} className="floated-image" alt="img-3" /> */}
                        <img src={"assets/images/gallery/about-2.jpg"} className="img-fluid" alt="img-4" />
                    </div>
                    
                    </div>
                   
                </div>
                
                <div className="col-lg-6">
                    <div className="about-inner">
                    <div className="section-title-box">
                        <div className="section-tagline">Our Mission &amp; Vision</div>
                       
                        <h2 className="section-title">
                        Building a police service that is trusted
                        </h2>
                        <p>
                        The LNP works everyday knowing that citizens expect us to be professional in whatever we do.
                        We are moving forward and building a Police service that can be trusted to fight crime and keep everyone safe.
                        </p>
                    </div>
                   
                    <div className="row">
                        <div className="col-xl-6 col-lg-12 col-md-6">
                        <div className="about-card">
                            <h4 className="about-title">
                            <i className="fa-solid fa-circle-check" />
                            Mission
                            </h4>
                            <p className="about-text">
                            Our Mission is to proactively prevent, detect, and investigate crime. We are dedicated to 
                            protecting life, property, and other human rights, focusing on the vulnerable. We strive 
                            to maintain public peace and respond to community security needs, ensuring a safe and secure 
                            environment.
                            </p>
                        </div>
                        
                        </div>
                        
                        <div className="col-xl-6 col-lg-12 col-md-6">
                        <div className="about-card">
                            <h4 className="about-title">
                            <i className="fa-solid fa-circle-check" />
                           Vision
                            </h4>
                            <p className="about-text">
                            The Liberia National Police's Vision is to provide an effective, efficient, and professional 
                            Police Service that is deeply rooted in the community and unwaveringly adheres to the principle 
                            of democratic policing, ensuring the protection of democratic values at all times.
                            </p>
                        </div>
                        
                        </div>
                        
                    </div>
                    
                    {/* <div className="about-author-box">
                        <div className="about-author-image">
                            <img src={gallery04} className="img-fluid" alt="img-5" />
                        </div>
                       
                        <div className="about-author-box-meta">
                        <h5>Mr. Kevin Martin</h5>
                        <span>City Mayor</span>
                        </div>
                       
                    </div> */}
                    
                    </div>
                   
                </div>
                
                </div>
                
            </div>
        </section>
    </>
  )
}

export default Intro