import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Contact from '../components/contact/Main';
import Footer from '../components/Footer/Main';
import Home from '../components/home/Main';
import Hometwo from '../components/hometwo/Main';
import Pageabout from '../components/pages/about/Main';
import Admincommand from '../components/pages/admincommand/Main';
import Causes from '../components/pages/causes/Main';
import Causesdeatils from '../components/pages/causesdeatils/Main';
import Commend from '../components/pages/commendofficer/Main';
import Communityservice from '../components/pages/communityservice/Main';
import Reviewboard from '../components/pages/creviewboard/Main';
import Reporting from '../components/pages/crimereport/Main';
import Commandcsd from '../components/pages/csdcommand/Main';
import Statistics from '../components/pages/dataandstatistics/Main';
import Departmentdetails from '../components/pages/departmentdeatils/Main';
import Departments from '../components/pages/departments/Main';
import Events from '../components/pages/events/Main';
import Eventdeatils from '../components/pages/eventsdeatils/Main';
import Questions from '../components/pages/frequestquestion/Main';
import Gender from '../components/pages/gender/Main';
import Equality from '../components/pages/genderequality/Main';
import Igcommand from '../components/pages/igcommand/Main';
import Lnphistory from '../components/pages/lnphistory/Main';
import Missionandvision from '../components/pages/missionandvision/Main';
import News from '../components/pages/News/Main';
import Newsdeatils from '../components/pages/newsdeatils/Main';
import Opcommand from '../components/pages/operationcommand/Main';
import Policeacademy from '../components/pages/policeacademy/Main';
import Clearance from '../components/pages/policeclearance/Main';
import Portfolio from '../components/pages/Portfolio/Main';
import Portfoliodeatils from '../components/pages/portfoliodeatils/Main';
import Protectionsection from '../components/pages/protectionsection/Main';
import Allresources from '../components/pages/resources/Main';
import Services from '../components/pages/services/Main';
import Servicesdeatils from '../components/pages/servicesdeatils/Main';
import Standards from '../components/pages/standards/Main';
import Strategicplan from '../components/pages/strategicplan/Main';
import Team from '../components/pages/team/Main';
import Teamdeatils from '../components/pages/Teamdeatils/Main';
import Tcommand from '../components/pages/trainingcommand/Main';





function Index() {

  const location = useLocation();
  const path = location.pathname
  useEffect(() => {
    window.scroll(0, 0)
}, [path]);

  return (
    <>

        <Routes>

          <Route path="/" element={<Home />} />
          <Route path="/hometwo" element={<Hometwo />} />
          <Route path="/about" element={<Pageabout />} />
          <Route path="/lnphistory" element={<Lnphistory />} />
          <Route path="/missionandvision" element={<Missionandvision />} />
          <Route path="/strategicplan" element={<Strategicplan />} />
          <Route path="/policeacademy" element={<Policeacademy />} />
          <Route path="/communityservice" element={<Communityservice />} />
          <Route path="/gender" element={<Gender />} />
          <Route path="/standards" element={<Standards />} />
          <Route path="/protectionsection" element={<Protectionsection />} />
          <Route path="/igcommand" element={<Igcommand />} />
          <Route path="/admincommand" element={<Admincommand />} />
          <Route path="/opcommand" element={<Opcommand />} />
          <Route path="/commandcsd" element={<Commandcsd/>} />
          <Route path="/tcommand" element={<Tcommand/>} />
          <Route path="/clearance" element={<Clearance/>} />
          <Route path="/reporting" element={<Reporting/>} />
          <Route path="/allresources" element={<Allresources/>} />
          <Route path="/statistics" element={<Statistics/>} />
          <Route path="/commend" element={<Commend/>} />
          <Route path="/reviewboard" element={<Reviewboard/>} />
          <Route path="/questions" element={<Questions/>} />
          <Route path="/equality" element={<Equality/>} />
          <Route path="/team" element={<Team />} />
          <Route path="/teamdetails" element={<Teamdeatils />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfoliodeatils" element={<Portfoliodeatils />} />
          <Route path="/causes" element={<Causes />} />
          <Route path="/causedetails" element={<Causesdeatils />} />
          <Route path="/services" element={<Services />} />
          <Route path="/servicedetails" element={<Servicesdeatils />} />
          <Route path="/departments" element={<Departments />} />
          <Route path="/departmentdetails" element={<Departmentdetails />} />
          <Route path="/events" element={<Events />} />
          <Route path="/eventdetails" element={<Eventdeatils />} />
          <Route path="/news" element={<News />} />
          <Route path="/newsdetails" element={<Newsdeatils />} />
          <Route path="/contact" element={<Contact />} />

        </Routes>

        <Footer/>

    </>
  )
}

export default Index