import React from 'react';
import { Link } from 'react-router-dom';


function Reviewboard() {

  return (
    <>
        <section className="department-details-section">
            <div className="container">
              <div className="row">
                
                <div className="col-12 col-lg-4 col-xl-4">
                  <div className="sidebar">
                    <div className="sidebar-widget-list-inner">
                      <ul>
                        <li>
                          <Link to="/policeacademy">
                            Police Academy
                            <i className="fa-solid fa-arrow-right-long" />
                          </Link>
                        </li>
                        <li>
                          <Link to="/communityservice">
                            Community service
                            <i className="fa-solid fa-arrow-right-long" />
                          </Link>
                        </li>
                       
                        <li>
                          <Link to="/standards">
                            Professional Standard
                            <i className="fa-solid fa-arrow-right-long" />
                          </Link>
                        </li>
                        <li>
                          <Link to="/protectionsection">
                            Women &amp; Children Protection Section
                            <i className="fa-solid fa-arrow-right-long" />
                          </Link>
                        </li>
                      </ul>
                      
                    </div>
                    
                    <div className="sidebar-widget sidebar-widget-card">
                      <div className="sidebar-widget-card-icon">
                        <i className="flaticon-question" />
                      </div>
                      
                      <div className="sidebar-widget-card-content">
                        <h3>
                          <Link to="/contact">Get Any Help?</Link>
                        </h3>
                        <p>
                          Learn more about the Liberia national police 
                          Civilian &amp; Complaints Review Board 

                        </p>
                      </div>
                      
                    </div>
                    
                    {/* <div className="sidebar-widget">
                      <div className="sidebar-widget-box-icon">
                        <i className="flaticon-pdf" />
                      </div>
                      
                      <div className="sidebar-widget-box-content">
                        <h3>
                          Company briefing update of the <br /> 2022 year
                        </h3>
                        <Link to="/departmentdetails" className="btn btn-primary">
                          Download
                        </Link>
                      </div>
                      
                    </div> */}
                    
                  </div>
                  
                </div>
                
                <div className="col-lg-8">
                  <div className="department-details-imgbox">
                    <img src={"assets/images/department/review-1.jpg"} className="img-fluid" alt="img-159" />
                    <Link to="#" />
                    <div className="department-details-img-icon">
                      <i className="flaticon-police-badge-1" />
                    </div>
                  
                  </div>
                  
                  <div className="department-details-content-box">
                    <h4 className="department-details-title">Objectives of the Review Board </h4>
                    <p>
                    It shall be the objectives of the Review Board to:<br/>
                        a. Hold the Liberia National Police accountable to the public in the performance of its functions;<br/>
                        b. Promote adherence to human rights, professionalism, discipline, transparency and accountability within the Liberia National Police;<br/>
                        c. Enhance public trust in the Liberia National Police;<br/>
                        d. Enhance a measure of cordiality between the police and the public by additionally employing mechanisms for realizing restorative justice in all cases to be handled by the Review Board.


                    </p>
                  </div>
                  
                  <div className="department-details-box">
                   
                    <p>
                    <h4 className="department-details-title">Functions of the Review Board  </h4>
                    Pursuant to its objectives, the Review Board shall perform its functions as set out in the Police Act of 2015 and Regulations, and the functions shall include:<br/>

                    a. Receiving, processing and determining any complaint made against the Liberia National Police, any Police Officer or Civilian Personnel;<br/>
                    b. Promulgating rules of procedure for the manner in which complaints are received and processed and feedback relayed to the complainant;<br/>
                    c. Establishing an alternative dispute resolution program as an option for the public to use to resolve minor complaints against the Liberia National Police, Police Officers or Civilian Personnel.

                    </p>
                  </div>
               
                </div>
               
              </div>
            </div>
        </section>

    </>
  )
}

export default Reviewboard