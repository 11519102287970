import React from 'react'
import { Link } from 'react-router-dom'

function Blog() {
  return (
    <>
        <section className="blog-section">
            <div className="container">
                <div className="blog-box">
                <div className="section-title-box text-center">
                    <div className="section-tagline">DIRECT FROM OUR NEWS POSTS</div>
                    <h2 className="section-title">Latest News <br /> and Press Releases </h2>
                </div>
                </div>
                <div className="row row-gutter-y-155">
                <div className="col-lg-4">
                    <div className="blog-card">
                    <div className="blog-card-image">
                        <img src={"assets/images/blog/blog-1.png"} className="img-fluid" alt="img-22" />
                        <Link to="/#" > </Link>
                    </div>
                    
                    <div className="blog-card-date">
                        <Link to="/#">29MAR</Link>
                    </div>
                    
                    <div className="blog-card-content">
                        <div className="blog-card-meta">
                        <span className="author">
                            by<Link to="/#">Admin</Link>
                        </span>
                       
                        <span className="comment">
                            <Link to="/#">20 Comments</Link>
                        </span>
                       
                        </div>
                        
                        <h4>
                        <Link to="/#">
                        Chinese Gov't to Make Huge Donation to LNP
                        </Link>
                        </h4>
                        <p>
                        Chinese Government to Make Huge Donation to LNP The Government..
                        </p>
                    </div>
                  
                    </div>
                    
                </div>
               
                <div className="col-lg-4">
                    <div className="blog-card">
                    <div className="blog-card-image">
                        <img src={"assets/images/blog/blog-3.png"} className="img-fluid" alt="img-23" />
                        <Link to="/#" />
                    </div>
                    
                    <div className="blog-card-date">
                        <Link to="/#">25MAR</Link>
                    </div>
                    
                    <div className="blog-card-content">
                        <div className="blog-card-meta">
                        <span className="author">
                            by<Link to="/#">Admin</Link>
                        </span>
                       
                        <span className="comment">
                            <Link to="/#">72 Comments</Link>
                        </span>
                       
                        </div>
                        
                        <h4>
                        <Link to="/#"> US Government Donates Uniforms to the LNP</Link>
                        </h4>
                        <p>
                        US Government Donates Uniforms to the LNP. The United States Government..
                        </p>
                    </div>
                  
                    </div>
                    
                </div>
               
                <div className="col-lg-4">
                    <div className="blog-card">
                    <div className="blog-card-image">
                        <img src={"assets/images/blog/blog-2.jpg"} className="img-fluid" alt="img-24" />
                        <Link to="/#" />
                    </div>
                    
                    <div className="blog-card-date">
                        <Link to="/#">21MAR</Link>
                    </div>
                    
                    <div className="blog-card-content">
                        <div className="blog-card-meta">
                        <span className="author">
                            by<Link to="/#">Admin</Link>
                        </span>
                       
                        <span className="comment">
                            <Link to="/#">52 Comments</Link>
                        </span>
                       
                        </div>
                        
                        <h4>
                        <Link to="/#">
                        Public Service Announcements
                        </Link>
                        </h4>
                        <p>
                        Click here for the latest announcements from the LNP
                        </p>
                    </div>
                  
                    </div>
                    
                </div>
               
                </div>
                
            </div>
        </section>

    </>
  )
}

export default Blog