import React from 'react';
import { Link } from 'react-router-dom';




function Opcommand() {

    const settings = {
        items: 4,
        nav:false,
        margin:30,
        loop: true,
        autoplay:true,
        smartSpeed:2000,
        responsive:{
            0:{
                    items:1,
            },
            575:{
                    items:2,
            },
            767:{
                    items:3,
            },
            991: {
                items:4,
            }
        }
      };

  return (
    <>
        <section className="portfolio-details">
            <div className="container">
                <img src={"assets/images/portfolio/operationcom-1.jpg"} className="img-fluid" alt="img-109" />
                <div className="portfolio-details-info">
                
               
                <div className="portfolio-details-info-item portfolio-details-info-socials">
                    <a href="https://twitter.com/">
                        <i className="fa-brands fa-twitter" />
                    </a>
                    <a href="https://www.facebook.com/">
                        <i className="fa-brands fa-facebook" />
                    </a>
                    <a href="https://in.pinterest.com/">
                        <i className="fa-brands fa-pinterest-p" />
                    </a>
                    <a href="https://www.instagram.com/">
                        <i className="fa-brands fa-instagram" />
                    </a>
                </div>
                
                </div>
                
                <div className="portfolio-details-content">
                <div className="portfolio-details-content-title">
                    <h3>Operation Command</h3>
                </div>
               
                <div className="portfolio-details-content-text">
                    <p>
                    The operations department of a police force is the central hub responsible 
                    for the direct implementation of law enforcement activities and public safety measures. 
                    This department includes various units and divisions, such as patrol, traffic, 
                    investigations, and specialized response teams. The primary role of the operations 
                    department is to maintain public order, prevent and investigate crimes, respond to emergencies, 
                    and enforce laws. Officers within this department are often the first responders to incidents, 
                    whether it's a routine patrol, a traffic stop, or a serious crime scene. They are responsible 
                    for engaging with the community, gathering intelligence, and ensuring a visible police presence 
                    to deter criminal activities.{" "}
                    </p>
                    <p>
                    In addition to routine policing, the operations department also handles specialized tasks that 
                    require unique skills and training. This includes units like the Special Weapons and Tactics (SWAT) 
                    team, K-9 units, and units focused on cybercrime, narcotics, or counter-terrorism. These specialized 
                    units are equipped to deal with specific threats and situations that require a higher level of 
                    expertise and tactical response. The operations department also coordinates large-scale public 
                    events, managing security and crowd control to ensure public safety. By strategically deploying 
                    resources and personnel, the operations department plays a critical role in upholding law and order, 
                    protecting citizens, and responding to a wide range of emergencies and criminal activities.
                    </p>
                    {/* <p>
                    All you need to do is unlock the riches that have been locked away
                    with-in you. Being lucky in life is the result of putting yourself
                    into action for good luck to happen to you. You’ve probably heard the
                    statement “The harder I work the luckier I get”.{" "}
                    </p> */}
                    
                </div>
                
                </div>
               
            </div>
        </section>
        <section className="cta-three">
            <div className="cta-three-inner">
                <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="cta-three-content text-center">
                        <h2 className="section-title text-white">
                            The Liberia Natioal Police Offers a Wide <br /> Range of Needed Services
                        </h2>
                        <Link to="/contact" className="btn btn-primary"> Discover More{" "} </Link>
                    </div>
                    
                    </div>
                    
                </div>
                
                </div>
                
            </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/> 
                    <br/> 
                    <br/> 
        </section>
             

    </>
  )
}

export default Opcommand