import React, { useEffect } from 'react';
import Bredcom from '../../Bredcom/Main';
import Header from '../../headerone/Main';
import Blog from '../../menhome/Blog';
import Event from '../../menhome/Event';
import Newlast from '../../menhome/Newlast';
import Protection from '../menprotectionsection/Protection';



function Main() {

  useEffect(() => {
    document.getElementById("title").innerHTML="Protection Section";
  }, [])

  return (
    <>
         <Header/>

         <div className="page-wrapper">
          <Bredcom 
            subtitle="Home"
            title="protection section"
            subtitledown="Protection Section"
           />
            <Protection/>
            <Event/>
            <Blog/>
            <Newlast/>
         </div>
    </>
  )
}

export default Main