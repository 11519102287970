import React from 'react'

function Counter() {
  return (
    <>
        <section className="funfact-section">
            <div className="container">
                <div className="row">
                <div className="col-xl-3 col-md-6">
                    <div className="funfact-counter-item">
                    <div className="funfact-counter-box">
                        <div className="funfact-counter-icon">
                            <i className="flaticon-police-badge-1" />
                            {/* <i className="flaticon-police" /> */}
                        </div>
                        
                        <div className="funfact-counter-number">
                        <h3 className="counter-number">5</h3>
                        <span className="funfact-counter-number-postfix">k</span>
                        </div>
                        
                    </div>
                    
                    <p className="funfact-text">
                      Police Personnel
                    </p>
                    </div>
                    
                </div>
               
                <div className="col-xl-3 col-md-6">
                    <div className="funfact-counter-item">
                    <div className="funfact-counter-box">
                        <div className="funfact-counter-icon">
                        <i className="far fa-user" />
                        {/* <i className="flaticon-team" /> */}
                        </div>
                        
                        <div className="funfact-counter-number">
                        <h3 className="counter-number">80</h3>
                        <span className="funfact-counter-number-postfix">%</span>
                        </div>
                        
                    </div>
                    
                    <p className="funfact-text">
                        Male Officers
                        {/* <br /> Region Covers */}
                    </p>
                    </div>
                    
                </div>
               
                <div className="col-xl-3 col-md-6">
                    <div className="funfact-counter-item">
                    <div className="funfact-counter-box">
                        <div className="funfact-counter-icon">
                        <i className="flaticon-art" />
                        {/* <i className="flaticon-landscape" /> */}
                        </div>
                        
                        <div className="funfact-counter-number">
                        <h3 className="counter-number">20</h3>
                        <span className="funfact-counter-number-postfix">%</span>
                        </div>
                        
                    </div>
                    
                    <p className="funfact-text">
                        Female Officers
                    </p>
                    </div>
                    
                </div>
               
                <div className="col-xl-3 col-md-6">
                    <div className="funfact-counter-item">
                    <div className="funfact-counter-box">
                        <div className="funfact-counter-icon">
                        <i className="flaticon-police" />
                        {/* <i className="flaticon-barn-3" /> */}
                        </div>
                        
                        <div className="funfact-counter-number">
                        <h3 className="counter-number">12</h3>
                        <span className="funfact-counter-number-postfix">K</span>
                        </div>
                        
                    </div>
                    
                    <p className="funfact-text">
                        Required Strength
                    </p>
                    </div>
                    
                </div>
               
                </div>
                
            </div>
        </section>
    </>
  )
}

export default Counter