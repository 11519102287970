import React, { useEffect } from 'react';
import Bredcom from '../../Bredcom/Main';
import Header from '../../headerone/Main';
import Blog from '../../menhome/Blog';
import Newlast from '../../menhome/Newlast';
import Gender from '../mengender/Gender';



function Main() {

  useEffect(() => {
    document.getElementById("title").innerHTML="Gender";
  }, [])

  return (
    <>
         <Header/>

         <div className="page-wrapper">
          <Bredcom 
            subtitle="Home"
            title="gender"
            subtitledown="Gender"
           />
            <Gender/>
            <Blog/>
            <Newlast/>
         </div>
    </>
  )
}

export default Main