import React from 'react';
import { Link } from 'react-router-dom';

function First() {
    return (
      <>
          <section className="department-details-section">
              <div className="container">
                <div className="row">
                  
                  <div className="col-12 col-lg-4 col-xl-4">
                    <div className="sidebar">
                      
                    <div className="sidebar-widget-list-inner">
                        <ul>
                          <li>
                            <Link to="/communityservice">
                              Community Services
                              <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/gender">
                              Gender Unit
                              <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                          </li>
                          {/* <li>
                            <Link to="/services">
                              Internal Affairs
                              <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/communityservice">
                             Community Relations
                              <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                          </li> */}
                          <li>
                            <Link to="/policeacademy">
                              Police Academy
                              <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/protectionsection">
                              Women &amp; Children Protection Section
                              <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                          </li>
                        </ul>
                        
                      </div>
                      
                      <div className="sidebar-widget sidebar-widget-card">
                        <div className="sidebar-widget-card-icon">
                          <i className="flaticon-question" />
                        </div>
                        
                        <div className="sidebar-widget-card-content">
                          <h3>
                            <Link to="/contact">Get Any Help?</Link>
                          </h3>
                          <p>
                            Learn more about the Professional Standards Department 
                            of the Liberia National Police Force. 

                          </p>
                        </div>
                        
                      </div>
                      
                      {/* <div className="sidebar-widget">
                        <div className="sidebar-widget-box-icon">
                          <i className="flaticon-pdf" />
                        </div>
                        
                        <div className="sidebar-widget-box-content">
                          <h3>
                            Company briefing update of the <br /> 2022 year
                          </h3>
                          <Link to="/departmentdetails" className="btn btn-primary">
                            Download
                          </Link>
                        </div>
                        
                      </div> */}
                      
                    </div>
                    
                  </div>
                  
                  <div className="col-lg-8">
                    <div className="department-details-imgbox">
                      <img src={"assets/images/department/standards-1.jpg"} className="img-fluid" alt="img-159" />
                      <Link to="#" />
                      <div className="department-details-img-icon">
                        <i className="flaticon-police-badge-1" />
                      </div>
                    
                    </div>
                    
                    <div className="department-details-content-box">
                      <h4 className="department-details-title">Professional Standards Department</h4>
                      <p>
                      The Professional Standards Department (PSD) of the Liberia national police force is responsible for maintaining and enforcing high standards 
                      of conduct, ethics, and professionalism among its officers. This department serves as a watchdog within the organization, 
                      ensuring that all members adhere to legal regulations, departmental policies, and codes of conduct. Its primary objective 
                      is to uphold the integrity of the police force and foster a culture of accountability and transparency. 
                      A key function of the PSD is to investigate complaints or allegations of misconduct against police officers. This includes a 
                      wide range of issues such as abuse of power, discrimination, dishonesty, and unlawful behavior.
                      </p>
                    </div>
                    
                    <div className="department-details-box">
                      <div className="department-details-policy">
                        <span>
                        Investigations conducted by the PSD are thorough, impartial, and objective, aiming to uncover the truth and provide fair 
                        outcomes for both officers and the public. By holding officers accountable for their actions, the PSD helps maintain public 
                        trust and confidence in law enforcement.
                        </span>
                       
                      </div>
                     
                      <p>
                      Additionally, the PSD is involved in developing and implementing policies and procedures that govern officer conduct. 
                      These guidelines are designed to prevent misconduct before it occurs, emphasizing proactive measures such as training programs, 
                      ethical education, and regular performance evaluations. By setting clear expectations and providing support to officers 
                      in maintaining professional standards, the PSD contributes to a positive organizational culture where integrity and respect are paramount.
                      </p>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                    </div>
        
                  </div>
                 
                </div>
              </div>
          </section>

      </>
    )
}

export default First
