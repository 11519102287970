import React, { useEffect } from 'react'
import Bredcom from '../../Bredcom/Main'
import Header from '../../headerone/Main'
import Blog from '../../menhome/Blog'
import Newlast from '../../menhome/Newlast'
import Questions from '../menfrequestquestion/Questions'


function Main() {

  useEffect(() => {
    document.getElementById("title").innerHTML="Frequently Asked Questions";
  }, [])

  return (
    <>
        <Header/>

        <div className="page-wrapper">
        <Bredcom 
            subtitle="Home"
            title="frequently asked questions"
            subtitledown="Frequently Asked Questions"
           />
            <Questions/>
            <Blog/>
            <Newlast/>
        </div>
    </>
  )
}

export default Main