import React from 'react';
import { Link } from 'react-router-dom';




function Equality() {

    const settings = {
        items: 4,
        nav:false,
        margin:30,
        loop: true,
        autoplay:true,
        smartSpeed:2000,
        responsive:{
            0:{
                    items:1,
            },
            575:{
                    items:2,
            },
            767:{
                    items:3,
            },
            991: {
                items:4,
            }
        }
      };

  return (
    <>
        <section className="portfolio-details">
            <div className="container">
                <img src={"assets/images/portfolio/equality-1.jpg"} className="img-fluid" alt="img-109" />
                <div className="portfolio-details-info">
                
               
                <div className="portfolio-details-info-item portfolio-details-info-socials">
                    <a href="https://twitter.com/">
                        <i className="fa-brands fa-twitter" />
                    </a>
                    <a href="https://www.facebook.com/">
                        <i className="fa-brands fa-facebook" />
                    </a>
                    <a href="https://in.pinterest.com/">
                        <i className="fa-brands fa-pinterest-p" />
                    </a>
                    <a href="https://www.instagram.com/">
                        <i className="fa-brands fa-instagram" />
                    </a>
                </div>
                
                </div>
                
                <div className="portfolio-details-content">
                <div className="portfolio-details-content-title">
                    <h3>Opportunities for Women in the Police Service </h3>
                </div>
               
                <div className="portfolio-details-content-text">
                    <p>
                    The Liberia National Police encourages gender equality in the police service because it believes 
                    that a diverse and inclusive workforce enhances the effectiveness and fairness of law enforcement. 
                    By promoting gender equality, the LNP aims to ensure that both men and women have equal 
                    opportunities to contribute to the force and advance in their careers. This approach not only 
                    helps to create a more supportive and equitable working environment but also brings a variety of 
                    perspectives and skills to the organization. With a gender-balanced police force, the LNP can 
                    better understand and respond to the unique needs and concerns of different community members, 
                    thereby fostering greater trust and cooperation between the police and the public. Ultimately, 
                    gender equality in the police service is seen as a key factor in achieving comprehensive and 
                    just policing in Liberia..{" "}
                    </p>
                    <p>
                    Gender equality in the police force is crucial for creating a balanced and inclusive work environment 
                    that reflects the diversity of the communities it serves. Historically, policing has been a 
                    male-dominated field, but increasing the representation of women and other gender minorities is essential 
                    for broadening perspectives within the force. A diverse police force can improve community relations, 
                    enhance problem-solving, and bring a wider range of skills and experiences to law enforcement. 
                    By promoting gender equality, police departments can ensure that all officers, regardless of gender, 
                    have equal opportunities for recruitment, training, and advancement. This inclusivity not only benefits 
                    the officers themselves but also enhances the overall effectiveness of policing by fostering a more 
                    comprehensive understanding of the needs and concerns of the public.
                    </p>
                    
                    
                </div>
                
                </div>
               
            </div>
        </section>
        <section className="cta-three">
            <div className="cta-three-inner">
                <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="cta-three-content text-center">
                        <h2 className="section-title text-white">
                            The Liberia Natioal Police Offers a Wide <br /> Range of Needed Services
                        </h2>
                        <Link to="/contact" className="btn btn-primary"> Discover More{" "} </Link>
                    </div>
                    
                    </div>
                    
                </div>
                
                </div>
                
            </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/> 
                    <br/> 
                    <br/> 
        </section>
             

    </>
  )
}

export default Equality