import React from 'react'
// import Gallery01 from '../../assets/image/gallery/ig-1.png'
// import Shapes01 from '../../assets/image/shapes/shape-1.png'


function Mayor() {
  return (
    <>
        <section className="mayor-section">
            <div className="container">
                <div className="row">
                <div className="col-lg-6">
                    <div className="mayor-box">
                    <div className="section-title-box">
                        <div className="section-tagline">Hon. Gregory O. W. Coleman</div>
                        <h2 className="section-title">Inspector General of <br/>Police, Republic of Liberia </h2>
                        <p>
                        Appearing for his confirmation hearing before the Senate Committee 
                        on National Security, Defense, Intelligence, and Veteran Affairs,  
                        IGP. Coleman affirmed that his philosophy focuses on a people-first mentality 
                        as what's suitable for the Liberian people is good for the Liberia National Police.
                        </p>
                    </div>
                    <div className="mayor-icon-box">
                        {/* <div className="mayor-icon">
                        <i className="flaticon-professor" />
                        </div> */}
                       
                        <h4 className="mayor-icon-title">
                        <em>Building a Police service that is trusted</em>
                        </h4>
                        <p>“I am committed to improving the way we communicate with the community, 
                            ensuring that our actions are understandable and justifiable...”</p>
                    </div>
                    <div> 
                    <p>“I encourage you to share your concerns, suggestions, and ideas. 
                        Together, we will develop innovative approaches to policing that 
                        address the unique needs of our community. I will hold our officers accountable for their actions, and I expect 
                        nothing less than the highest standards of professionalism and integrity.” </p>
                    </div>
                    {/* <ul className="list-unstyled list-style-one">
                        <li>
                        <i className="fa-solid fa-circle-check" />
                        <p>“I am committed to improving the way we communicate with the community, 
                            ensuring that our actions are understandable and justifiable.”</p>
                        </li>
                       
                        <li>
                        <i className="fa-solid fa-circle-check" />
                        <p>“I encourage you to share your concerns, suggestions, and ideas. 
                            Together, we will develop innovative approaches to policing that 
                            address the unique needs of our community.” </p>
                        </li>
                       
                        <li>
                        <i className="fa-solid fa-circle-check" />
                        <p>“I will hold our officers accountable for their actions, and I expect 
                            nothing less than the highest standards of professionalism and integrity,” </p>
                        </li>
                       
                    </ul> */}
                    
                    </div>
                    
                </div>
                
                <div className="col-lg-6">
                    <div className="mayor-img">
                    {/* <img src={"assets/images/shapes/shape-1.png"} className="floated-image-one" alt="img-7" /> */}
                    <img src={"assets/images/gallery/ig-1.png"} alt="img-8" />
                    {/* <div className="mayor-name">Mr. Kevin Martin</div>      */}
                </div>
                    
                </div>
                
                </div>
                
            </div>
        </section>
    </>
  )
}

export default Mayor