import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
// import Portfolio01 from '../../assets/image/portfolio/portfolio-1.jpg'


function Portfolio() {

    const settings = {
        items: 4,
			nav:false,
			margin:30,
			loop: true,
			autoplay:true,
			smartSpeed:2000,
			responsive:{
				0:{
						items:1,
				},
				575:{
						items:2,
				},
				767:{
						items:3,
				},
				991: {
					items:4,
				}
			}
      };


  return (
    <>
        <section className="portfolio-section">
            <div className="section-title-box text-center">
                    <div className="section-tagline">Liberia National Police</div>
                    <h2 className="section-title">
                    Explore the Liberia National <br />
                    Police
                    </h2>
            </div>
            <div className="portfolio-content conatainer-fluid">
                <OwlCarousel className="portfolio-carousel owl-carousel owl-theme" {...settings}>

                    <div className="item">
                        <div className="portfolio-card">
                        <img src={"assets/images/portfolio/portfolio-1.png"} className="img-fluid" alt="img-9" />
                        <div className="portfolio-card-meta">
                            <div className="portfolio-card-text">
                            <Link to="/igcommand">IG Command</Link>
                            </div>
                            <div className="portfolio-card-title">
                            <Link to="/igcommand">Handles All Police Affairs</Link>
                            </div>
                        </div>
                        </div>
                       
                    </div>
                   
                    <div className="item">
                        <div className="portfolio-card">
                        <img src={"assets/images/portfolio/portfolio-2.png"} className="img-fluid" alt="img-10" />
                        <div className="portfolio-card-meta">
                            <div className="portfolio-card-text">
                            <Link to="/opcommand">Operation</Link>
                            </div>
                            <div className="portfolio-card-title">
                            <Link to="/opcommand">Ensure Your Security</Link>
                            </div>
                        </div>
                       
                        </div>
                       
                    </div>
                   
                    <div className="item">
                        <div className="portfolio-card">
                        <img src={"assets/images/portfolio/portfolio-3.png"} className="img-fluid" alt="img-11" />
                        <div className="portfolio-card-meta">
                            <div className="portfolio-card-text">
                            <Link to="/admincommand">Administration</Link>
                            </div>
                            <div className="portfolio-card-title">
                            <Link to="/igcommand">Coordinate All LNP Programs</Link>
                            </div>
                        </div>
                       
                        </div>
                       
                    </div>
                   
                    <div className="item">
                        <div className="portfolio-card">
                        <img src={"assets/images/portfolio/portfolio-4.png"} className="img-fluid" alt="img-12" />
                        <div className="portfolio-card-meta">
                            <div className="portfolio-card-text">
                            <Link to="/tcommand">Training Command</Link>
                            </div>
                            <div className="portfolio-card-title">
                            <Link to="/policeacademy">Police Capacity Development</Link>
                            </div>
                        </div>
                       
                        </div>
                       
                    </div>
                   
                 </OwlCarousel>
            </div>
        </section>
    </>
  )
}

export default Portfolio