import React from 'react';
import { Link } from 'react-router-dom';


function Community() {

  return (
    <>
        <section className="department-details-section">
            <div className="container">
              <div className="row">
                
                <div className="col-12 col-lg-4 col-xl-4">
                  <div className="sidebar">
                  <div className="sidebar-widget-list-inner">
                        <ul>
                          <li>
                            <Link to="/policeacademy">
                              Police academy
                              <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/gender">
                              Gender Unit
                              <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                          </li>
                          {/* <li>
                            <Link to="/services">
                              Internal Affairs
                              <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/communityservice">
                             Community Relations
                              <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                          </li> */}
                          <li>
                            <Link to="/standards">
                              Professional Standard
                              <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/protectionsection">
                              Women &amp; Children Protection Section
                              <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                          </li>
                        </ul>
                        
                      </div>

                    
                    <div className="sidebar-widget sidebar-widget-card">
                      <div className="sidebar-widget-card-icon">
                        <i className="flaticon-question" />
                      </div>
                      
                      <div className="sidebar-widget-card-content">
                        <h3>
                          <Link to="/contact">Get Any Help?</Link>
                        </h3>
                        <p>
                          Learn more about the Liberia national police community service
                          initiatives and how to implement the LNP crime prevention an safety in your 
                          communities.

                        </p>
                      </div>
                      
                    </div>
                    
                    {/* <div className="sidebar-widget">
                      <div className="sidebar-widget-box-icon">
                        <i className="flaticon-pdf" />
                      </div>
                      
                      <div className="sidebar-widget-box-content">
                        <h3>
                          Company briefing update of the <br /> 2022 year
                        </h3>
                        <Link to="/departmentdetails" className="btn btn-primary">
                          Download
                        </Link>
                      </div>
                      
                    </div> */}
                    
                  </div>
                  
                </div>
                
                <div className="col-lg-8">
                  <div className="department-details-imgbox">
                    <img src={"assets/images/department/community-1.jpg"} className="img-fluid" alt="img-159" />
                    <Link to="#" />
                    <div className="department-details-img-icon">
                      <i className="flaticon-police-badge-1" />
                    </div>
                  
                  </div>
                  
                  <div className="department-details-content-box">
                    <h4 className="department-details-title">Police ensuring order and safety </h4>
                    <p>
                      The Liberia national police community service unit plays a vital role in fostering positive relationships between law 
                      enforcement agencies and the communities they serve. This aspect of policing emphasizes proactive engagement and collaboration 
                      with local residents, businesses, and organizations to address community concerns, enhance safety, and build trust. 
                      One of the primary goals of police community service is to bridge the gap between law enforcement and the public, creating 
                      opportunities for mutual understanding and cooperation.
                      Community policing initiatives often involve officers participating in various community service activities such as neighborhood 
                      patrols, school visits, youth programs, and community events. These efforts go beyond traditional law enforcement duties by allowing 
                      officers to interact with community members in non-enforcement contexts. By being visible and approachable, police officers can build 
                      rapport and establish meaningful connections with residents, which can lead to improved communication and cooperation in addressing local issues.
                    </p>
                  </div>
                  
                  <div className="department-details-box">
                    <div className="department-details-policy">
                      <span>
                      One of the key benefits of police community service is its role in enhancing public trust and confidence in law enforcement. When officers actively 
                      engage with community members in non-enforcement settings, it humanizes the badge and creates opportunities for meaningful interactions.
                      </span>
                     
                    </div>
                   
                    <p>
                    Moreover, the police community service initiatives are instrumental in promoting crime prevention and public safety. By collaborating with 
                    community members, police can gather valuable intelligence about local crime trends, concerns, and suspicious activities. 
                    This partnership enables law enforcement agencies to tailor their strategies and resources more effectively to meet the specific needs 
                    of each neighborhood. Ultimately, police community service contributes to creating safer and more resilient communities where residents 
                    feel empowered and supported in working together with law enforcement to address challenges and promote well-being.
                    </p>
                  </div>
               
                </div>
               
              </div>
            </div>
        </section>

    </>
  )
}

export default Community