import React from 'react'
import { Link } from 'react-router-dom'

function Commend() {
  return (
    <>
        <section className="news-details-section">
            <div className="container">
                <div className="row">
                <div className="col-lg-8">
                    <div className="news-details-box-image">
                    <div className="news-details-box-image-inner">
                        <img src={"assets/images/blog/commend-1.jpg"} className="img-fluid" alt="img-193" />
                        <Link to="/newsdetails" className="news-details-box-date">  </Link>
                    </div>
                    
                    </div>
                   
                    <div className="news-details-meta-box">
                    {/* <div className="news-details-meta-box-inner">
                        <span className="author"> by<Link to="/newsdetails">Admin</Link> </span>
                       
                        <span className="comment">
                            <Link to="/newsdetails">02 Comments</Link>
                        </span>
                       
                    </div> */}
                    
                    </div>
                    
                    <div className="news-details-content-box">
                    <h4>Commend and Applaud an Officer </h4>
                    <p>
                    Commending a police officer for exemplary service is an important 
                    way to acknowledge their dedication and positive impact on the community. 
                    To commend an officer, one can start by writing a formal letter or email 
                    to the police department, detailing the officer's actions and the specific 
                    incident that prompted the commendation. It's important to include the 
                    officer's name, badge number (if known), and the date and location of the 
                    incident. Providing a clear and concise account of how the officer went 
                    above and beyond their duties will help the department understand the 
                    significance of the commendation. Expressing gratitude and highlighting 
                    the positive outcomes of the officer's actions can also make the 
                    commendation more meaningful..{" "}
                    </p>
                    
                    </div>
                   
                    <div className="news-details-comment-form">
                    <h3>Enter your message here</h3>
                    <form className="contact-form contact-form-validated" action="assets/inc/sendemail.php" method="post" >
                        <div className="row row-gutter-10">
                        <div className="col-12 col-lg-6">
                            <input type="text" className="input-text" placeholder="Your name" name="name" aria-required="true" />
                        </div>
                       
                        <div className="col-12 col-lg-6">
                            <input type="email" className="input-text" placeholder="Email address" name="email" aria-required="true" />
                        </div>
                       
                        <div className="col-12 col-lg-12">
                            <textarea name="message" placeholder="Write a message" className="input-text " aria-required="true" defaultValue={""} />
                        </div>
                      
                        <div className="col-12 col-lg-12">
                            <button className="btn btn-primary">Submit Comment</button>
                        </div>
                      
                        </div>
                       
                    </form>
                   
                    </div>
                   
                </div>
               
                <div className="col-lg-4">
                    <div className="sidebar">
                    <div className="sidebar-form-content">
                        <div className="sidebar__item sidebar__item--search">
                        <form action="#" className="sidebar__search">
                            <label htmlFor="search" className="sr-only">
                            search here
                            </label>
                           
                            <input type="text" placeholder="Search Here" />
                            <button type="submit" aria-label="search submit" className="thm-btn" >
                            <i className="flaticon-search-interface-symbol" />
                            </button>
                          
                        </form>
                       
                        </div>
                        
                    </div>
                    
                    
                    {/* <div className="sidebar-widget sidebar-widget-recent-post">
                        <h4>Recent Posts</h4>
                        <div className="sidebar-recent-post">
                        <div className="sidebar-recent-post-img">
                            <img src={Blogdeatils02} alt="img-197" />
                        </div>
                       
                        <div className="sidebar-recent-post-content">
                            <div className="sidebar-meta">
                            <div className="sidebar-meta-item">
                                <div className="sidebar-meta-icon">
                                <span className="author">
                                    by<Link to="/newsdetails">Admin</Link>
                                </span>
                               
                                </div>
                               
                            </div>
                            
                            <div className="sidebar-post-title">
                                <h5>
                                <Link to="#">Learn how access to municipal council</Link>
                                </h5>
                            </div>
                          
                            </div>
                           
                        </div>
                       
                        </div>
                        
                        <div className="sidebar-recent-post">
                        <div className="sidebar-recent-post-img">
                            <img src={Blogdeatils03} alt="img-198" />
                        </div>
                       
                        <div className="sidebar-recent-post-content">
                            <div className="sidebar-meta">
                            <div className="sidebar-meta-item">
                                <div className="sidebar-meta-icon">
                                <span className="author">
                                    by<Link to="/newsdetails">Admin</Link>
                                </span>
                               
                                </div>
                               
                            </div>
                            
                            <div className="sidebar-post-title">
                                <h5>
                                <Link to="#">Learn how access to municipal council</Link>
                                </h5>
                            </div>
                          
                            </div>
                           
                        </div>
                       
                        </div>
                        
                        <div className="sidebar-recent-post">
                        <div className="sidebar-recent-post-img">
                            <img src={Blogdeatils04} alt="img-199" />
                        </div>
                       
                        <div className="sidebar-recent-post-content">
                            <div className="sidebar-meta">
                            <div className="sidebar-meta-item">
                                <div className="sidebar-meta-icon">
                                <span className="author">
                                    by<Link to="/newsdetails">Admin</Link>
                                </span>
                               
                                </div>
                               
                            </div>
                            
                            <div className="sidebar-post-title">
                                <h5>
                                <Link to="#">Learn how access to municipal council</Link>
                                </h5>
                            </div>
                          
                            </div>
                           
                        </div>
                       
                        </div>
                        
                    </div> */}

                    
                    <div className="sidebar-widget sidebar-widget-recent-category">
                        <div className="sidebar-widget-recent-category-box">
                        <h4 className="section-title text-left">Categories</h4>
                        <ul className="list-unstyled">
                            <li>
                            <Link to="#">
                                Good conduct
                                <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                            <li>
                            <Link to="#">
                                Best Traffic Officer
                                <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                            <li>
                            <Link to="#">
                                Upholding Culture 
                                <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                            <li>
                            <Link to="#">
                                Devlopement Oriented
                                <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                            <li>
                            <Link to="#">
                                Upright Police Officer
                                <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                        </ul>
                        
                        </div>
                        
                    </div>
                    
                    <div className="sidebar-widget sidebar-widget-tag">
                        <h4>Tags</h4>
                        <div className="sidebar-widget-tag-inner">
                        <Link to="/igcommand">IG Command</Link>
                        <Link to="/igcommand">Administration</Link>
                        <Link to="/igcommand">Operation</Link>
                        <Link to="/igcommand">CSD Command</Link>
                        <Link to="/igcommand">Community Service</Link>
                        </div>
                        
                    </div>
                   
                    </div>
                   
                </div>
               
                </div>
               
            </div>
        </section>
    </>
  )
}

export default Commend