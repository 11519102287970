import React from 'react'
import { Link } from 'react-router-dom'
// import Logo from '../../assets/image/logo-light.png'

function Main() {
  return (
    <>
        <section className="footer">
            <div className="footer-inner">
                <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                    <div className="footer-widget-logo">
                        <Link to="/">
                          {/* <img src={Logo} className="img-fluid" alt="img-25" /> */}
                          <img src={"assets/images/logo-f.png"} className="img-fluid" alt="img-1"/>
                        </Link>
                    </div>
                    
                    <div className="footer-widget-text">
                        <p>
                        The LNP works everyday knowing that citizens expect 
                        the Police to be professional in carrying out their duties.
                        </p>
                    </div>
                   
                    <div className="footer-widget-socials">
                        <a href="https://twitter.com/">
                            <i className="fa-brands fa-twitter" />
                        </a>
                        <a href="https://www.facebook.com/theLNP/">
                            <i className="fa-brands fa-facebook" />
                        </a>
                        <a href="https://in.pinterest.com/">
                            <i className="fa-brands fa-pinterest-p" />
                        </a>
                        <a href="https://www.instagram.com/">
                            <i className="fa-brands fa-instagram" />
                        </a>
                    </div>
                   
                    </div>
                   
                    <div className="col-lg-3">
                    <div className="footer-widget">
                        <div className="footer-widget-explore">
                        <h4 className="footer-widget-title">Explore</h4>
                        <ul className="list-unstyled">
                            <li>
                                <Link to="/clearance">Police Clearance</Link>
                            </li>
                            <li>
                                <Link to="/#">Community Relations</Link>
                            </li>
                            <li>
                                <Link to="/commend">Commend An Officer</Link>
                            </li>
                            <li>
                                <Link to="/reporting">File Complaints</Link>
                            </li>
                            <li>
                                <Link to="/reporting">Report Crime</Link>
                            </li>
                        </ul>
                        
                        </div>
                        
                    </div>
                    
                    </div>
                    
                    <div className="col-lg-2">
                    <div className="footer-widget">
                        <div className="footer-widget-department">
                        <h4 className="footer-widget-title">LNP Organization</h4>
                        <ul className="list-unstyled">
                            <li>
                            <Link to="/igcommand">IGP Command</Link>
                            </li>
                            <li>
                            <Link to="/admincommand">Administration</Link>
                            </li>
                            <li>
                            <Link to="/opcommand">Operation Command</Link>
                            </li>
                            <li>
                            <Link to="/commandcsd">CSD Command</Link>
                            </li>
                            <li>
                            <Link to="/tcommand">Training Command</Link>
                            </li>
                        </ul>
                        
                        </div>
                        
                    </div>
                    
                    </div>
                    
                    <div className="col-lg-3">
                    <div className="footer-widget">
                        <div className="footer-widget-contact">
                        <h4 className="footer-widget-title">Contact</h4>
                        <p>
                           Charles Alphonso Caine Building,
                            <br />
                            Capitol Hill, Monrovia, Liberia
                        </p>
                        </div>
                       
                        <div className="footer-widget-contact-list">
                        <i className="fa-solid fa-envelope" />
                        <div className="footer-widget-contact-item">
                            <Link to="mailto:needhelp@company.com">info@lnp.gov.lr</Link>
                        </div>
                        
                        </div>
                        
                        <div className="footer-widget-contact-list">
                        <i className="fa-solid fa-phone" />
                        <div className="footer-widget-contact-item">
                            <Link to="tel:+231-800-122">0770 800-122</Link>
                        </div>
                        
                        </div>
                        <div className="footer-widget-contact-list">
                        <i className="fa-solid fa-phone" />
                        <div className="footer-widget-contact-item">
                            <Link to="tel:+231-800-911">0770 800-911</Link>
                        </div>
                        
                        </div>
                        
                    </div>
                    
                    </div>
                    
                </div>
                
                </div>
                
            </div>
            <div className="bottom-footer">
                <div className="conatiner">

                    <p>© Copyright Liberia National Police 2024 | All Rights Reserved | Design by <a href="https://rhotelhub.org">Rhotel Digital & Innovation Hub</a> </p>

                </div>
                
            </div>
        </section>
    </>
  )
}

export default Main