import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';


function Slider() {

    const settings = {
        loop:true,
			autoplay:true,
			nav:true,
			items: 1,
			dots:false,
			navText: ['<i class="fa-solid fa-arrow-left-long"></i>','<i class="fa-solid fa-arrow-right-long"></i>']
      };


  return (
    <>
        <section className="main-slider">
            
            <OwlCarousel className="main-slider-swiper owl-carousel owl-theme" {...settings}>

                <div className="item">
                <div className="item-slider-bg" style={{ backgroundImage:'url(assets/images/bg/slider-bg-1.png)' }}/>
                <div className="container">
                    <div className="row">
                    <div className="col-md-12">
                        <div className="slider-content"> <div className="slider-tagline"> We are here to Serve and Protect
                         </div>
                        <h1 className="section-title"> Promoting <br /> Gender Equality <br /> in Police Service
                        </h1>
                        <Link to="/lnphistory" className="btn btn-primary"> Read More </Link>
                        </div>
                        
                    </div>
                    
                    </div>
                    
                </div>
                
                </div>
              
                <div className="item">
                <div className="item-slider-bg" style={{ backgroundImage:'url(assets/images/bg/slider-bg-2.png)' }} />
                <div className="container">
                    <div className="row">
                    <div className="col-md-12">
                        <div className="slider-content">
                        <div className="slider-tagline"> We are here to Serve and Protect </div>
                        <h1 className="section-title"> Ensuring Your<br /> Protection and <br/>Safety </h1>
                            <Link to="/lnphistory" className="btn btn-primary"> Read More </Link>
                        </div>      
                    </div>
                    
                    </div>
                    
                </div>
                
                </div>

                <div className="item">
                <div className="item-slider-bg" style={{ backgroundImage:'url(assets/images/bg/slider-bg-3.png)' }} />
                <div className="container">
                    <div className="row">
                    <div className="col-md-12">
                        <div className="slider-content">
                        <div className="slider-tagline"> We are here to Serve and Protect </div>
                        <h1 className="section-title"> Fighting Crime <br /> and Keeping<br/>  Everyone Safe </h1>
                            <Link to="/lnphistory" className="btn btn-primary"> Read More </Link>
                        </div>      
                    </div>
                    
                    </div>
                    
                </div>
                
                </div>

                <div className="item">
                <div className="item-slider-bg" style={{ backgroundImage:'url(assets/images/bg/slider-bg-4.png)' }} />
                <div className="container">
                    <div className="row">
                    <div className="col-md-12">
                        <div className="slider-content">
                        <div className="slider-tagline"> We are here to Serve and Protect </div>
                        <h1 className="section-title"> Building A <br /> Police Service <br/>That is Trusted </h1>
                            <Link to="/lnphistory" className="btn btn-primary"> Read More </Link>
                        </div>      
                    </div>
                    
                    </div>
                    
                </div>
                
                </div>
                
                
              
            </OwlCarousel>

        </section>

        <section className="department-section">
            <div className="container">
                <div className="department-section-inner">
                <div className="row row-gutter-y-40">
                    <div className="col-xl-2 col-lg-4 col-md-6">
                    <div className="department-card">
                        <div className="department-card-icon">
                        <Link to="#">
                            <i className="flaticon-parthenon" />
                        </Link>
                        </div>
                        
                        <div className="department-card-content">
                        <h5>
                            <Link to="/reporting">Case Investigation</Link>
                        </h5>
                        </div>
                       
                    </div>
                    
                    </div>
                    
                    <div className="col-xl-2 col-lg-4 col-md-6">
                    <div className="department-card">
                        <div className="department-card-icon">
                        <Link to="#">
                            <i className="flaticon-suitcase" />
                        </Link>
                        </div>
                        
                        <div className="department-card-content">
                        <h5>
                            <Link to="/clearance">Police Clearance</Link>
                        </h5>
                        </div>
                       
                    </div>
                    
                    </div>
                    
                    <div className="col-xl-2 col-lg-4 col-md-6">
                    <div className="department-card">
                        <div className="department-card-icon">
                        <Link to="#">
                            <i className="flaticon-industry" />
                        </Link>
                        </div>
                        
                        <div className="department-card-content">
                        <h5>
                            <Link to="/statistics">Data &amp; Statistics</Link>
                        </h5>
                        </div>
                       
                    </div>
                    
                    </div>
                    
                    <div className="col-xl-2 col-lg-4 col-md-6">
                    <div className="department-card">
                        <div className="department-card-icon">
                        <Link to="#">
                            <i className="flaticon-team" />
                            {/* <i className="far fa-user-circle" /> */}
                        </Link>
                        </div>
                        
                        <div className="department-card-content">
                        <h5>
                            <Link to="/equality">Gender Equality</Link>
                        </h5>
                        </div>
                       
                    </div>
                    
                    </div>
                    {/* <div className="col-xl-2 col-lg-4 col-md-6">
                    <div className="department-card">
                        <div className="department-card-icon">
                        <Link to="/departments">
                            <i className="flaticon-bus" />
                        </Link>
                        </div>
                        
                        <div className="department-card-content">
                        <h5>
                            <Link to="/#">Roads &amp; Traffic</Link>
                        </h5>
                        </div>
                       
                    </div>
                    
                    </div> */}
                    
                    <div className="col-xl-2 col-lg-4 col-md-6">
                    <div className="department-card">
                        <div className="department-card-icon">
                        <Link to="#">
                            <i className="flaticon-lotus" />
                        </Link>
                        </div>
                        
                        <div className="department-card-content">
                        <h5>
                            <Link to="/allresources">Freedom of Information</Link>
                        </h5>
                        </div>
                       
                    </div>
                    
                    </div>
                    
                    <div className="col-xl-2 col-lg-4 col-md-6">
                    <div className="department-card">
                        <div className="department-card-icon">
                        <Link to="#">
                            <i className="flaticon-balance-1" />
                        </Link>
                        </div>
                        
                        <div className="department-card-content">
                        <h5>
                            <Link to="/#">Laws &amp; Regulations</Link>
                        </h5>
                        </div>
                       
                    </div>
                    
                    </div>
                    
                </div>
                
                </div>
                
            </div>
            
            <div className="department-search-section">
                <div className="container">
                    <form className="department-search-form">
                        <input type="text" placeholder="Get quick response from our Support Unit" name="search" />
                        <button type="submit">See How We Are Protecting You &amp; The Nation</button>
                    </form>
                </div>
            </div>
        </section>
        
    </>
  )
}

export default Slider