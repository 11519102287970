import React from 'react';
import { Link } from 'react-router-dom';




function Commandcsd() {

    const settings = {
        items: 4,
        nav:false,
        margin:30,
        loop: true,
        autoplay:true,
        smartSpeed:2000,
        responsive:{
            0:{
                    items:1,
            },
            575:{
                    items:2,
            },
            767:{
                    items:3,
            },
            991: {
                items:4,
            }
        }
      };

  return (
    <>
        <section className="portfolio-details">
            <div className="container">
                <img src={"assets/images/portfolio/commandcsd-1.jpg"} className="img-fluid" alt="img-109" />
                <div className="portfolio-details-info">
                
               
                <div className="portfolio-details-info-item portfolio-details-info-socials">
                    <a href="https://twitter.com/">
                        <i className="fa-brands fa-twitter" />
                    </a>
                    <a href="https://www.facebook.com/">
                        <i className="fa-brands fa-facebook" />
                    </a>
                    <a href="https://in.pinterest.com/">
                        <i className="fa-brands fa-pinterest-p" />
                    </a>
                    <a href="https://www.instagram.com/">
                        <i className="fa-brands fa-instagram" />
                    </a>
                </div>
                
                </div>
                
                <div className="portfolio-details-content">
                <div className="portfolio-details-content-title">
                    <h3>Criminal Investigation Department Command</h3>
                </div>
               
                <div className="portfolio-details-content-text">
                    <p>
                    The Criminal Investigation Department (CSD), also known as the Criminal Investigation 
                    Division or Command, is a specialized unit within the police force tasked with 
                    investigating serious and complex crimes. This department focuses on criminal activities 
                    that require extensive investigation beyond routine law enforcement, such as homicides, 
                    organized crime, fraud, and other major offenses. Officers in the CSD are highly 
                    trained in forensic science, interrogation techniques, and evidence gathering, 
                    enabling them to handle intricate cases that often require a deep understanding of 
                    criminal behavior and legal procedures. The CSD works closely with other law enforcement 
                    agencies, forensic experts, and prosecutors to build solid cases that can withstand legal scrutiny.{" "}
                    </p>
                    <p>
                    The CSD Command is not only responsible for investigating crimes but also plays a key role in intelligence 
                    gathering and analysis. By collecting and analyzing data from various sources, the CSD helps to identify 
                    crime trends, track down fugitives, and prevent future criminal activities. This proactive approach is 
                    crucial in combating organized crime and other sophisticated criminal networks. Additionally, the CSD often 
                    handles sensitive cases involving high-profile individuals or complex legal issues, requiring a high degree 
                    of discretion and professionalism. Through meticulous investigation and collaboration with other 
                    law enforcement entities, the CSD Command is vital in maintaining public safety and ensuring justice is served.
                    </p>
                    {/* <p>
                    All you need to do is unlock the riches that have been locked away
                    with-in you. Being lucky in life is the result of putting yourself
                    into action for good luck to happen to you. You’ve probably heard the
                    statement “The harder I work the luckier I get”.{" "}
                    </p> */}
                    
                </div>
                
                </div>
               
            </div>
        </section>
        <section className="cta-three">
            <div className="cta-three-inner">
                <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="cta-three-content text-center">
                        <h2 className="section-title text-white">
                            The Liberia Natioal Police Offers a Wide <br /> Range of Needed Services
                        </h2>
                        <Link to="/contact" className="btn btn-primary"> Discover More{" "} </Link>
                    </div>
                    
                    </div>
                    
                </div>
                
                </div>
                
            </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/> 
                    <br/> 
                    <br/> 
        </section>
             

    </>
  )
}

export default Commandcsd