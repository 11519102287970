import React, { useEffect } from 'react'
import Bredcom from '../../Bredcom/Main'
import Header from '../../headerone/Main'
import Blog from '../../menhome/Blog'
import Newlast from '../../menhome/Newlast'
import Clearance from '../menpoliceclearance/Clearance'


function Main() {

  useEffect(() => {
    document.getElementById("title").innerHTML="Police Clearance";
  }, [])

  return (
    <>
        <Header/>

        <div className="page-wrapper">
        <Bredcom 
            subtitle="Home"
            title="police clearance"
            subtitledown="Police Clearance"
           />
            <Clearance/>
            <Blog/>
            <Newlast/>
        </div>
    </>
  )
}

export default Main