import React from 'react';
import { Link } from 'react-router-dom';




function Admincom() {

    const settings = {
        items: 4,
        nav:false,
        margin:30,
        loop: true,
        autoplay:true,
        smartSpeed:2000,
        responsive:{
            0:{
                    items:1,
            },
            575:{
                    items:2,
            },
            767:{
                    items:3,
            },
            991: {
                items:4,
            }
        }
      };

  return (
    <>
        <section className="portfolio-details">
            <div className="container">
                <img src={"assets/images/portfolio/admincom-1.jpg"} className="img-fluid" alt="img-109" />
                <div className="portfolio-details-info">
                
               
                <div className="portfolio-details-info-item portfolio-details-info-socials">
                    <a href="https://twitter.com/">
                        <i className="fa-brands fa-twitter" />
                    </a>
                    <a href="https://www.facebook.com/">
                        <i className="fa-brands fa-facebook" />
                    </a>
                    <a href="https://in.pinterest.com/">
                        <i className="fa-brands fa-pinterest-p" />
                    </a>
                    <a href="https://www.instagram.com/">
                        <i className="fa-brands fa-instagram" />
                    </a>
                </div>
                
                </div>
                
                <div className="portfolio-details-content">
                <div className="portfolio-details-content-title">
                    <h3>Administration Command</h3>
                </div>
               
                <div className="portfolio-details-content-text">
                    <p>
                    The administration department of a police force is a critical component 
                    that supports the operational aspects of law enforcement. 
                    This department is responsible for a wide range of functions that ensure 
                    the smooth and efficient running of the police organization. Key responsibilities 
                    include managing human resources, overseeing recruitment and training, handling 
                    finances and budgeting, and maintaining records and information systems. 
                    The administration department also coordinates logistics, such as the procurement 
                    and distribution of equipment and supplies, ensuring that officers have the necessary 
                    resources to perform their duties effectively. By managing these essential functions, 
                    the administration department enables frontline officers to focus on their primary 
                    role of maintaining public safety and enforcing the law..{" "}
                    </p>
                    <p>
                    The administration department of a police service typically includes several key personnel who play crucial roles in managing and supporting the various administrative functions of the organization. These key personnel often include:<br/>

                    a. Chief Administrative Officer (CAO): The CAO is usually the senior-most official in the administration department, overseeing all administrative functions and ensuring that the department operates smoothly. They coordinate between different administrative units and report directly to the police chief or commissioner.<br/>

                    b. Human Resources Manager: Responsible for recruitment, hiring, training, and managing personnel issues within the police force. The HR Manager also handles employee relations, benefits administration, and professional development programs.<br/>

                    c. Finance and Budget Officer: This officer oversees the financial operations of the police service, including budgeting, financial planning, and expenditure tracking. They ensure that funds are allocated appropriately and that the department adheres to financial regulations and policies.<br/>

                    d. Records and Information Management Officer: Responsible for maintaining and securing all official records and information within the police service. This includes personnel records, crime reports, and other sensitive data. They ensure that information is accurate, accessible, and protected.<br/>

                    e. Logistics and Supply Manager: Oversees the procurement, distribution, and maintenance of equipment and supplies necessary for police operations. This role ensures that officers have the tools and resources they need to perform their duties effectively.<br/>

                    f. Policy and Compliance Officer: Ensures that the police service adheres to all relevant laws, regulations, and internal policies. This officer is also involved in drafting new policies and procedures and monitoring compliance within the force.<br/>

                    d. Public Relations and Communications Officer: Manages the communication between the police service and the public, including handling media relations, public statements, and community outreach programs. They play a key role in shaping the public image of the police service.<br/>

                    These key personnel work together to manage the administrative tasks necessary to support the police service's overall mission and objectives.
                    </p>
                    <p>
                    These key personnel work together to manage the administrative tasks necessary to support the police service's overall mission and objectives..{" "}
                    </p>
                    
                </div>
                
                </div>
               
            </div>
        </section>
        <section className="cta-three">
            <div className="cta-three-inner">
                <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="cta-three-content text-center">
                        <h2 className="section-title text-white">
                            The Liberia Natioal Police Offers a Wide <br /> Range of Needed Services
                        </h2>
                        <Link to="/contact" className="btn btn-primary"> Discover More{" "} </Link>
                    </div>
                    
                    </div>
                    
                </div>
                
                </div>
                
            </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/> 
                    <br/> 
                    <br/> 
        </section>
             

    </>
  )
}

export default Admincom