import React from 'react';
import { Link } from 'react-router-dom';



function Ig() {

    const settings = {
        items: 3,
			nav:false,
			loop: true,
			margin:10,
			autoplay:true,
			smartSpeed:2000,
			responsive:{
				0:{
						items:1,
				},
				767:{
						items:2
				},
				1199: {
					items:3
				}
			}
      };

  return (
    <>
        <section className="event-details-section">
            <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="event-details-inner-box">
                        <img src={"assets/images/event/igcommand-1.jpg"} className="img-fluid" alt="img-173" />
                        {/* <div className="event-details-meta">
                            <div className="event-details-meta-number">
                                <span>28</span>
                            </div>
                            
                            <div className="event-details-meta-date">
                                <span>October 2022</span>
                            </div>
                        </div> */}
                        
                        </div>
                        
                    </div>
                   
                    <div className="col-lg-8">
                        <div className="event-details-content-box">
                        <h4>The IG Command: The police you can trust</h4>
                        <p>
                        The Inspector General (IG) Command of a police force represents the 
                        highest level of leadership and strategic oversight within the organization. 
                        The IG Command is typically led by the Inspector General of Police (IGP), 
                        who is responsible for setting the overall vision, mission, and policies 
                        of the police force. This leadership role involves coordinating various 
                        departments and ensuring that all units operate efficiently and in 
                        alignment with the law and the force's ethical standards. The IG Command 
                        oversees critical areas such as budget allocation, resource management, 
                        and inter-agency collaboration, working closely with government officials, 
                        other law enforcement agencies, and international partners to address crime 
                        and public safety issues on a broader scale.
                        </p>
                        <p>
                        Beyond administrative and strategic functions, the IG Command plays a pivotal 
                        role in maintaining the integrity and accountability of the police force. 
                        The Inspector General and their team are often tasked with internal affairs, 
                        conducting audits, and investigations into police conduct to ensure that 
                        officers uphold the law and adhere to professional standards. This includes 
                        addressing complaints of misconduct, corruption, or abuse of power within the force. 
                        The IG Command also engages in public relations and community outreach, fostering 
                        transparency and trust between the police and the community. By providing clear 
                        leadership and maintaining high standards of conduct, the IG Command helps to 
                        build a credible, effective, and respected police service that can effectively serve and protect the public.


                        </p>
                        <h3>Responsibility of the Command</h3>
                        <p>
                        The key responsibility of the Inspector General (IG) office in a police force is to provide strategic leadership 
                        and oversight to ensure the effective and ethical functioning of the entire organization. This includes setting 
                        the overall direction and priorities for the police force, formulating and implementing policies, and ensuring 
                        that all operational units are aligned with the law, organizational goals, and public expectations. 
                        The IG office is responsible for maintaining high standards of professionalism, integrity, 
                        and accountability within the police force.
                        </p>
                        <ul className="list-unstyled list-style-three">
                            <li>
                            <i className="fa-solid fa-circle-check" />
                            <h5> Provide strategic leadership.</h5>
                            </li>
                            
                            <li>
                            <i className="fa-solid fa-circle-check" />
                            <h5>Maintain high standards of professionalism.</h5>
                            </li>
                            
                            <li>
                            <i className="fa-solid fa-circle-check" />
                            <h5>Formulate and implement policies.</h5>
                            </li>
                            
                        </ul>
                        
                        <p>
                        The IG office also oversees internal affairs, conduct investigations into police misconduct, corruption, 
                        or violations of policy. This involves handling complaints from the public and within the force, 
                        ensuring that any issues are thoroughly investigated and addressed. The IG office also plays a 
                        critical role in managing relations with the government, other law enforcement agencies, 
                        and the public, often acting as the primary spokesperson for the police force. By providing 
                        clear guidance and maintaining oversight, the IG office ensures that the police force operates 
                        transparently, effectively, and in the best interests of public safety and justice.
                        </p>
                        
                        <div className="event-details-booking-button">
                            <Link to="/contact" className="btn btn-primary"> Book Your Appointment Here </Link>
                        </div>
                        
                        </div>
                        
                    </div>
                   
                    <div className="col-lg-4">
                        <div className="sidebar">
                        <div className="sidebar-widget-event-meta-box">
                            <div className="sidebar-widget-event-meta-details">
                            <div className="sidebar-widget-event-box">
                                <h6>Book an appointment with the IG</h6>
                                {/* <p>8:00 am to 2:00 pm</p> */}
                            </div>
                           
                            <div className="sidebar-widget-event-box">
                                <h6>Appointment Days:</h6>
                                <p>Monday - Saturday</p>
                            </div>
                           
                            <div className="sidebar-widget-event-box">
                                <h6>Time:</h6>
                                <p>10:30am - 2:00pm</p>
                                
                            </div>
                            <div className="sidebar-widget-event-box">
                                <h6>Categroy:</h6>
                                <p>
                                <Link to="#">Government</Link>, <Link to="#">Private..</Link>
                                </p>
                            </div>
                           
                            <div className="sidebar-widget-event-box">
                                <h6>Website:</h6>
                                <span>
                                <Link to="#">https://lnp.gov.lr</Link>
                                </span>
                            </div>
                           
                            <div className="sidebar-widget-event-box">
                                <h6>Location:</h6>
                                <p>Capitol Hill, Monrovia, Liberia</p>
                            </div>
                           
                            </div>
                            
                            <div className="sidebar-widget-event-meta-map">
                                <div className="google-map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d7066.088757744419!2d-10.793107093841611!3d6.30235075117256!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1721235445748!5m2!1sen!2sus" width={600} height={450} style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12083.735079362054!2d-74.01702461732008!3d40.785470167558394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258131938b8d5%3A0xe39c30a8afef2d96!2sWest%20New%20York%2C%20NJ%2007093%2C%20USA!5e0!3m2!1sen!2sin!4v1668832966742!5m2!1sen!2sin" width={600} height={450} style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" /> */}
                                </div>
                            </div>
 
                            <div className="sidebar-widget-event-meta-socials">
                                <a href="https://twitter.com/">
                                    <i className="fa-brands fa-twitter" />
                                </a>
                                <a href="https://www.facebook.com/">
                                    <i className="fa-brands fa-facebook" />
                                </a>
                                <a href="https://in.pinterest.com/">
                                    <i className="fa-brands fa-pinterest-p" />
                                </a>
                                <a href="https://www.instagram.com/">
                                    <i className="fa-brands fa-instagram" />
                                </a>
                            </div>
                            
                        </div>
                        
                        </div>
                       
                    </div>
                   
                    </div>
                    
            </div>
        </section>
        <section className="service-two-section">
            <div className="container">
                <div className="row row-gutter-y-40">
                <div className="col-12 col-lg-4 col-xl-4">
                    <div className="service-two-card">
                    <div className="service-two-imgbox">
                        <div className="service-two-image">
                        <img src={"assets/images/services/ig-2.jpg"} className="img-fluid" alt="img-28" />
                            <Link to="" />
                        </div>
                        
                    </div>
                   
                    <div className="service-two-card-content">
                        <h4>LNP Visitor Guide</h4>
                        <p>
                        The public is welcome to visit the Liberia National Headquarters. 
                        We are open 24/7. 
                        </p>
                        <Link to="/contact">
                        <i className="fa-solid fa-arrow-right-long" />
                        <span>Read More</span>
                        </Link>
                    </div>
                    
                    </div>
                    
                </div>
               
                <div className="col-12 col-lg-4 col-xl-4">
                    <div className="service-two-card">
                    <div className="service-two-imgbox">
                        <div className="service-two-image">
                        <img src={"assets/images/services/ig-3.jpg"} className="img-fluid" alt="img-29" />
                            <Link to="" />
                        </div>
                        
                    </div>
                   
                    <div className="service-two-card-content">
                        <h4>IG's Priorities</h4>
                        <p>
                        My philosophy is centered around good policing. That is why I am commited to  
                        building a police service that is trusted.
                        </p>
                        <Link to="/missionandvision">
                            <i className="fa-solid fa-arrow-right-long" />
                            <span>Read More</span>
                        </Link>
                    </div>
                    
                    </div>
                    
                </div>
               
                <div className="col-12 col-lg-4 col-xl-4">
                    <div className="service-link-box">
                    <h4 className="section-title">
                        The LNP <br />
                        Services
                    </h4>
                    <ul>
                        <li>
                        <Link to="/reporting">
                            Report a crime
                            <i className="fa-solid fa-chevron-right" />
                        </Link>
                        </li>
                        <li>
                        <Link to="/contact">
                            Apply to Join the Police service <i className="fa-solid fa-chevron-right" />
                        </Link>
                        </li>
                        <li>
                        <Link to="/clearance">
                        Police Clearance Certificate{" "}
                            <i className="fa-solid fa-chevron-right" />
                        </Link>
                        </li>
                        <li>
                        <Link to="/reporting">
                           File a complaints
                            <i className="fa-solid fa-chevron-right" />
                        </Link>
                        </li>
                        <li>
                        <Link to="/commend">
                            Commend an officer{" "}
                            <i className="fa-solid fa-chevron-right" />
                        </Link>
                        </li>
                        <li>
                        <Link to="/reporting">
                            Lost and found property
                            <i className="fa-solid fa-chevron-right" />
                        </Link>
                        </li>
                        <li>
                        <Link to="/reporting">
                            Report human trafficking <i className="fa-solid fa-chevron-right" />
                        </Link>
                        </li>
                    </ul>
                    
                    </div>
                   
                </div>
               
                </div>
                
            </div>
        </section>
        <section className="cta-three">
            <div className="cta-three-inner">
                <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="cta-three-content text-center">
                        <h2 className="section-title text-white">
                            The Liberia Natioal Police Offers a Wide <br /> Range of Needed Services
                        </h2>
                        <Link to="/contact" className="btn btn-primary"> Discover More{" "} </Link>
                    </div>
                    
                    </div>
                    
                </div>
                
                </div>
                
            </div>
        </section>

    </>
  )
}

export default Ig