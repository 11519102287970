import React from 'react'


function History() {
  return (
    <>
        <section className="about-one-section">
            <div className="container">
                <div className="row row-gutter-y-40">
                <div className="col-lg-12 col-xl-6">
                    <div className="about-one-inner">
                    <div className="section-tagline">LNP History</div>
                    
                    <h2 className="section-title">
                        Explore the Liberia National Police History
                    </h2>
                    <p>
                    Following independence, a system was established, and individuals were appointed as 'favorite servants' 
                    to maintain law and order. These individuals were compensated with remuneration from cases they handled 
                    or arrests made. In 1925, President Charles D. B. King took a significant step by ordering the formation 
                    of the first Constabulary Police Force, led by Major Abraham Butler as the first Superintendent of Police. 
                    This force, comprising fifteen (15) men strategically assigned, was primarily responsible for apprehending 
                    offenders and preserving law and order. 
                    </p>
                    <p>
                    In 1947, President William V. S. Tubman hired Sergeant Leone M. Jordan, a specialist from the Kansas City Police, 
                    USA, to reform and restructure the police force. By 1948, the job was completed, and one of the recommendations 
                    was to nationalize the police. So, there was a Legislative Enactment to legitimize the police. In 1956, the Liberia 
                    National Police was established as a legal institution enacted by law to protect and serve. This was a significant 
                    milestone in our history, and we continued to build on this legacy with the support of other law enforcement institutes.
                    </p>
                    <h5 className="about-one-inner-text">
                    In 2016, the Liberia National Police transitioned to a new era with the passage of the LNP Act of 2015, which repealed the 
                    Act amending the executive law with respect to the National Police Force and any other acts mandatory thereto; thereby, 
                    it established the Liberia National Police as a semi-autonomous agency under the Ministry of Justice.
                    </h5>
                    {/* <div className="row row-gutter-y-30">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                        <div className="about-one-card">
                            <div className="about-one-card-number">01</div>
                            <div className="about-one-card-content">
                            <h5>Going Above and Beyond</h5>
                            </div>
                        </div>
                       
                        </div>
                        
                        <div className="col-xl-6 col-lg-6 col-md-6">
                        <div className="about-one-card">
                            <div className="about-one-card-number">02</div>
                            <div className="about-one-card-content">
                            <h5>Committed to People First</h5>
                            </div>
                        </div>
                       
                        </div>
                        
                    </div> */}
                   
                    </div>
                    
                </div>
                
                <div className="col-lg-12 col-xl-6">
                    <div className="about-one-image">
                    {/* <img src={Shapes01} className="floated-image-one" alt="img-58" /> */}
                    <img src={"assets/images/gallery/history-1.jpg"} alt="img-59" className="img-fluid" />
                    </div>
                   
                </div>
                
                </div>
                
            </div>
        </section>
    </>
  )
}

export default History

