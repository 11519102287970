import React, { useEffect } from 'react';
import Bredcom from '../../Bredcom/Main';
import Header from '../../headerone/Main';
import Blog from '../../menhome/Blog';
import Newlast from '../../menhome/Newlast';
import Ig from '../menigcommand/Ig';


function Main() {

  useEffect(() => {
    document.getElementById("title").innerHTML="IG Command";
  }, [])
  
  
  return (
    <>
        <Header/>

        <div className="page-wrapper">
          <Bredcom 
            subtitle="Home"
            title="ig command"
            subtitledown="IG Command"
           />
            <Ig/>
            <Blog/>
            <Newlast/>
        </div>
    </>
  )
}

export default Main