import React from 'react';
import { Link } from 'react-router-dom';




function Tcommand() {

    const settings = {
        items: 4,
        nav:false,
        margin:30,
        loop: true,
        autoplay:true,
        smartSpeed:2000,
        responsive:{
            0:{
                    items:1,
            },
            575:{
                    items:2,
            },
            767:{
                    items:3,
            },
            991: {
                items:4,
            }
        }
      };

  return (
    <>
        <section className="portfolio-details">
            <div className="container">
                <img src={"assets/images/portfolio/tcommand-1.jpg"} className="img-fluid" alt="img-109" />
                <div className="portfolio-details-info">
                
               
                <div className="portfolio-details-info-item portfolio-details-info-socials">
                    <a href="https://twitter.com/">
                        <i className="fa-brands fa-twitter" />
                    </a>
                    <a href="https://www.facebook.com/">
                        <i className="fa-brands fa-facebook" />
                    </a>
                    <a href="https://in.pinterest.com/">
                        <i className="fa-brands fa-pinterest-p" />
                    </a>
                    <a href="https://www.instagram.com/">
                        <i className="fa-brands fa-instagram" />
                    </a>
                </div>
                
                </div>
                
                <div className="portfolio-details-content">
                <div className="portfolio-details-content-title">
                    <h3>Training Command</h3>
                </div>
               
                <div className="portfolio-details-content-text">
                    <p>
                    The Training Command of a police force is a crucial division responsible for the 
                    development and continuous improvement of officers' skills and knowledge. 
                    This department oversees the recruitment process, ensuring that new recruits meet 
                    the necessary standards and are adequately prepared for the challenges of law enforcement. 
                    The Training Command develops and implements a comprehensive curriculum that covers 
                    various aspects of policing, including criminal law, defensive tactics, 
                    firearms training, communication skills, and community relations. 
                    Additionally, they incorporate scenario-based training, which allows officers to practice 
                    responding to real-life situations in a controlled environment. This foundational training 
                    equips recruits with the essential tools and mindset required for effective and ethical policing..{" "}
                    </p>
                    <p>
                    Beyond initial training, the Training Command also focuses on the ongoing professional development 
                    of active-duty officers. This includes offering advanced courses, specialized training programs, 
                    and refresher courses to keep officers up-to-date with the latest techniques, technologies, and legal 
                    standards in law enforcement. Specialized training areas might include crisis negotiation, cybercrime 
                    investigation, and advanced forensics. The Training Command ensures that all officers are prepared 
                    to handle a wide range of scenarios and challenges, enhancing the overall effectiveness and 
                    adaptability of the police force. By prioritizing continuous learning and professional growth, 
                    the Training Command helps maintain high standards of competency and professionalism within the 
                    police force, ultimately contributing to better public safety and trust.
                    </p>
                    {/* <p>
                    All you need to do is unlock the riches that have been locked away
                    with-in you. Being lucky in life is the result of putting yourself
                    into action for good luck to happen to you. You’ve probably heard the
                    statement “The harder I work the luckier I get”.{" "}
                    </p> */}
                    
                </div>
                
                </div>
               
            </div>
        </section>
        <section className="cta-three">
            <div className="cta-three-inner">
                <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="cta-three-content text-center">
                        <h2 className="section-title text-white">
                            The Liberia Natioal Police Offers a Wide <br /> Range of Needed Services
                        </h2>
                        <Link to="/contact" className="btn btn-primary"> Discover More{" "} </Link>
                    </div>
                    
                    </div>
                    
                </div>
                
                </div>
                
            </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/> 
                    <br/> 
                    <br/> 
        </section>
             

    </>
  )
}

export default Tcommand