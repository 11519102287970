import React from 'react';
import { Link } from 'react-router-dom';
// import Deatils from '../../../assets/image/services/service-details-1.jpg'

function Statistics() {

    const settings = {
        items: 4,
        nav:false,
        margin:30,
        loop: true,
        autoplay:true,
        smartSpeed:2000,
        responsive:{
            0:{
                    items:1,
            },
            575:{
                    items:2,
            },
            767:{
                    items:3,
            },
            991: {
                items:4,
            }
        }
      };

  return (
    <>
        <section className="portfolio-details">
            <div className="container">
                <img src={"assets/images/portfolio/data-1.jpg"} className="img-fluid" alt="img-109" />
                <div className="portfolio-details-info">
                
               
                <div className="portfolio-details-info-item portfolio-details-info-socials">
                    <a href="https://twitter.com/">
                        <i className="fa-brands fa-twitter" />
                    </a>
                    <a href="https://www.facebook.com/">
                        <i className="fa-brands fa-facebook" />
                    </a>
                    <a href="https://in.pinterest.com/">
                        <i className="fa-brands fa-pinterest-p" />
                    </a>
                    <a href="https://www.instagram.com/">
                        <i className="fa-brands fa-instagram" />
                    </a>
                </div>
                
                </div>
                
                <div className="portfolio-details-content">
                <div className="portfolio-details-content-title">
                    <h3>LNP Data portal</h3>
                </div>
               
                <div className="portfolio-details-content-text">
                    <p>
                    The Liberia National Police (LNP) releases crime reports quarterly. These reports consist of absolutely 
                    confirmed cases—crimes that have been thoroughly investigated by the LNP and prosecuted by the relevant 
                    courts of the Republic of Liberia. This process ensures that the information provided in the reports 
                    is accurate and reliable, reflecting the true extent of criminal activities within the country. 
                    By publishing these reports, the LNP aims to inform the public and other stakeholders about the current 
                    crime trends and the outcomes of law enforcement efforts. The transparency and accountability 
                    demonstrated through these reports are vital for fostering public trust and ensuring the rule of law in Liberia..{" "}
                    </p>
                    
                </div>
                <br/>
                <br/>
                <br/>
        
                    <div className="container">
                <div className="row row-gutter-30">
               
                <div className="col-lg-6 col-xl-4">
                    <div className="service-one-card">
                    <div className="service-one-icon">
                        <Link to="#">
                        <i className="flaticon-pdf" />
                        </Link>
                    </div>
                    
                    <div className="service-one-card-content">
                        <h4>
                         Crime Statistics 
                        </h4>
                        <p>Download the Liberia National Police statistics for the last quarter.</p>
                        <Link to="https://drive.google.com/file/d/14PEs7QXOMIXkrKFjxw_1qzcH-DuJIFB_/view?usp=drive_link" className="btn btn-primary">Download</Link>
                    </div>
                    
                    </div>
                    
                </div>
                     
                </div>
                
            </div>
                
                </div>
               
            </div>
        </section>

        <section className="cta-three">
            <div className="cta-three-inner">
                <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="cta-three-content text-center">
                        <h2 className="section-title text-white">
                            The Liberia Natioal Police Offers a Wide <br /> Range of Needed Services
                        </h2>
                        <Link to="/contact" className="btn btn-primary"> Discover More{" "} </Link>
                    </div>
                    
                    </div>
                    
                </div>
                
                </div>
                
            </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/> 
                    <br/> 
                    <br/> 
        </section>
             

    </>
  )
}

export default Statistics

                    