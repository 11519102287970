import React from 'react';
import { Link } from 'react-router-dom';


function Gender() {

  return (
    <>
        <section className="department-details-section">
            <div className="container">
              <div className="row">
                
                <div className="col-12 col-lg-4 col-xl-4">
                  <div className="sidebar">
                    <div className="sidebar-widget-list-inner">
                      <ul>
                        <li>
                          <Link to="/policeacademy">
                            Police Academy
                            <i className="fa-solid fa-arrow-right-long" />
                          </Link>
                        </li>
                        <li>
                          <Link to="/communityservice">
                            Community service
                            <i className="fa-solid fa-arrow-right-long" />
                          </Link>
                        </li>
                        <li>
                          <Link to="/reviewboard">
                            Review Board
                            <i className="fa-solid fa-arrow-right-long" />
                          </Link>
                        </li>
                        
                        <li>
                          <Link to="/standards">
                            Professional Standard
                            <i className="fa-solid fa-arrow-right-long" />
                          </Link>
                        </li>
                        <li>
                          <Link to="/protectionsection">
                            Women &amp; Children Protection Section
                            <i className="fa-solid fa-arrow-right-long" />
                          </Link>
                        </li>
                      </ul>
                      
                    </div>
                    
                    <div className="sidebar-widget sidebar-widget-card">
                      <div className="sidebar-widget-card-icon">
                        <i className="flaticon-question" />
                      </div>
                      
                      <div className="sidebar-widget-card-content">
                        <h3>
                          <Link to="/contact">Get Any Help?</Link>
                        </h3>
                        <p>
                          Learn more about the Liberia national police gender initiatives 

                        </p>
                      </div>
                      
                    </div>
                    
                    {/* <div className="sidebar-widget">
                      <div className="sidebar-widget-box-icon">
                        <i className="flaticon-pdf" />
                      </div>
                      
                      <div className="sidebar-widget-box-content">
                        <h3>
                          Company briefing update of the <br /> 2022 year
                        </h3>
                        <Link to="/departmentdetails" className="btn btn-primary">
                          Download
                        </Link>
                      </div>
                      
                    </div> */}
                    
                  </div>
                  
                </div>
                
                <div className="col-lg-8">
                  <div className="department-details-imgbox">
                    <img src={"assets/images/department/gender-1.jpg"} className="img-fluid" alt="img-159" />
                    <Link to="#" />
                    <div className="department-details-img-icon">
                      <i className="flaticon-police-badge-1" />
                    </div>
                  
                  </div>
                  
                  <div className="department-details-content-box">
                    <h4 className="department-details-title">Gender equity in the police force </h4>
                    <p>
                    The overall goal of the Gender Section is to help reduce and eliminate gender inequality, discrimination, exclusion, and marginalization, by addressing the needs and concerns of women, girls, men and boys. 
                    It creates opportunities for equitable distribution of resources and access to services, and establishes a clear vision to deliver services, policies, procedures and practices based on equality for women 
                    and men and girls and boys.
                    For a successful implementation, the Gender Section sets out principles for the implementation of the strategies in achieving the objectives contemplated in both this Administrative Instruction and other 
                    relevant regulations:  <br/>
                    A.	The principle of inclusiveness, which requires that police officers consult equally with women and men in the country in all decisions that affect them thereby integrating into those policies the 
                    perspectives of all the components of the community they are expected to assist. <br/>

                    B.	The principle of non-discrimination, which requires that Law Enforcement Personnel ensure support for policies and decisions that uphold the equal rights of women and girls and ensures their protection 
                    from Sexual and Gender-Based Violence (SGBV) and harmful traditional practices. <br/>

                    C.	The principle of protection and promotion of human rights requires the LNP to be the most visible institution, since they have direct and regular contact with members of the community on a daily basis. 
                    The LNP is therefore an important instrument for building confidence within the Liberian society for the respect and promotion of Human Rights. This practice is fundamental to how a modern and principled 
                    police service delivery, in fulfillment of its legal authorities and mission, should be conducted. Fundamental to sound police work is an understanding of human rights in a democratic society; and this understanding must be 
                    mainstreamed into the work of the LNP.<br/>
                
                    D.	The principle of gender balance requires that the staffing profile at headquarters and in the leeward counties reflects the LNP’s institutional commitments to the equal representation of men and women at all post levels.<br/>

                    E.	The principle of efficiency in police activities, which requires that all human resources capacity in post-conflict societies (women, men, boys and girls), are effectively harnessed to build and sustain the peace process.

                    </p>
                  </div>
                  
                  <div className="department-details-box">
                    <div className="department-details-policy">
                      <span>
                      "Gender equality in policing isn't just about representation; it's about creating a culture where diversity strengthens our ability to serve and protect every community... Gender should not be a barrier to participation 
                      and advancement in any field, especially in law enforcement where diversity can enhance understanding and service to the community." - Kristin Hannah"
                      </span>
                     
                    </div>
                   
                    <p>
                    The Gender Section of the Liberia National Police (LNP) was established with the mandate to ensure the following: <br/>
                      1.	Actively ensure gender equity in all aspect of LNP’s training, administration and operations; <br/>
                      2.	Ensure coherence between and among all internal human resources policies; <br/>
                      3.	Ensure that male and female officers of the LNP work in an atmosphere of dignity and respect; <br/>
                      4.	Ensure that LNP work towards achieving a diverse work force at all levels; <br/>
                      5.	Ensure that the value of the UN 1325 resolution involving recruitments, promotions, staffing capacity development; retention and gender mainstreaming is adhered to; <br/>
                      6.	Ensure that the National Gender action plan is implemented by the LNP through the Gender Section;<br/>
                      7.	Investigate all forms of SGBV, GBV, Harassments and other gender related cases both internal and external.

                    </p>
                  </div>
               
                </div>
               
              </div>
            </div>
        </section>

    </>
  )
}

export default Gender