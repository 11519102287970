import React, { useEffect } from 'react'
import Bredcom from '../../Bredcom/Main'
import Header from '../../headerone/Main'
import Blog from '../../menhome/Blog'
import Newlast from '../../menhome/Newlast'
import Commend from '../mencommendofficer/Commend'


function Main() {

  useEffect(() => {
    document.getElementById("title").innerHTML="Commend an officer";
  }, [])

  return (
    <>
        <Header/>

        <div className="page-wrapper">
        <Bredcom 
            subtitle="Home"
            title="commend an officer"
            subtitledown="Commend an Officer"
           />
            <Commend/>
            <Blog/>
            <Newlast/>
        </div>
    </>
  )
}

export default Main