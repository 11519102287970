import React, { useEffect } from 'react';
import Bredcom from '../../Bredcom/Main';
import Header from '../../headerone/Main';
import Blog from '../../menhome/Blog';
import Event from '../../menhome/Event';
import Newlast from '../../menhome/Newlast';
import Tcommand from '../mentrainingcommand/Tcommand';



function Main() {

  useEffect(() => {
    document.getElementById("title").innerHTML="Training Command";
  }, [])

  return (
    <>
         <Header/>

         <div className="page-wrapper">
          <Bredcom 
            subtitle="Home"
            title="training command"
            subtitledown="Training Command"
           />
            <Tcommand/>
            <Event/>
            <Blog/>
            <Newlast/>
         </div>
    </>
  )
}

export default Main