import React from 'react'

function First() {
  return (
    <>
        <section className="contact-section">
            <div className="container">
                <div className="row">
                <div className="col-lg-4">
                    <div className="contact-box">
                    <div className="section-tagline">WRITE A MESSAGE</div>
                    
                    <h1 className="section-title">Remember we are here to protect you</h1>
                    {/* <p>
                        There are certain attributes of a profession and one has to catch
                        hold of them in order to efficiently and grow in that business. I
                        share my experience as an interior designer.{" "}
                    </p> */}
                    </div>
                   
                </div>
               
                <div className="col-lg-8">
                    <form action="assets/inc/sendemail.php" className="contact-form  contact-form-validated"
                    method="post" >
                    <div className="row row-gutter-10">
                        <div className="col-12 col-lg-6">
                        <input type="text" id="name" className="input-text" placeholder="Your name" name="name" aria-required="true" />
                        </div>
                        
                        <div className="col-12 col-lg-6">
                        <input type="email" id="email" className="input-text" placeholder="Email address" name="email" aria-required="true" />
                        </div>
                        
                        <div className="col-12 col-lg-6">
                         <input type="text" id="phone" className="input-text" placeholder="Phone number" name="phone" aria-required="true" />
                        </div>
                        
                        <div className="col-12 col-lg-6">
                            <input type="text" id="subject" className="input-text" placeholder="Subject" name="subject" aria-required="true" />
                        </div>
                        
                        <div className="col-12 col-lg-12">
                        <textarea name="message" placeholder="Write a message" className="input-text" aria-required="true" defaultValue={""} />
                        </div>
                       
                        <div className="col-12 col-lg-12">
                            <button className="btn btn-primary">Send a Message</button>
                        </div>
                       
                    </div>
                    </form>
                  
                </div>
                </div>
            </div>
        </section>


        <div className="contact-gmap-section">
            <div className="container">
            <div className="responsive-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15863.05614353424!2d-10.7787887!3d6.29471!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xf0a0354aca65a3d%3A0xb169acf3cc459640!2sRhotel%20Innovation%20House!5e0!3m2!1sen!2s!4v1712763589768!5m2!1sen!2s"
                width={600} height={450} title="latest" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
            </div>
            
            </div>
            
        </div>

        <div className="cta-four-section">
            <div className="container">
            <div className="cta-four-inner">
                <div className="row row-gutter-y-20 ">
                <div className="col-12 col-lg-6 col-xl-3">
                    <div className="cta-four-content">
                    <i className="flaticon-help" />
                    <div className="cta-four-content-box">
                        <span>Have Question?</span>
                        <p> +231 0770 800-911</p>
                    </div>
                    
                    </div>
                   
                </div>
               
                <div className="col-12 col-md-6 col-lg-6 col-xl-3">
                    <div className="cta-four-content">
                    <i className="flaticon-envelope-3" />
                    <div className="cta-four-content-box">
                        <span>Write Email</span>
                        <p>info@lnp.gov.lr</p>
                    </div>
                    
                    </div>
                   
                </div>
               
                <div className="col-12 col-lg-6 col-xl-4">
                    <div className="cta-four-content">
                    <i className="flaticon-location-pin" />
                    <div className="cta-four-content-box">
                        <span>Visit Our Office</span>
                        <p>Capitol Hill, Monrovia, Liberia</p>
                    </div>
                    
                    </div>
                   
                </div>
               
                <div className="col-12 col-lg-6 col-xl-2">
                    <div className="cta-four-content">
                    <div className="cta-four-widget-socials">
                        <a href="https://twitter.com/">
                            <i className="fa-brands fa-twitter" />
                        </a>
                        <a href="https://www.facebook.com/theLNP/">
                            <i className="fa-brands fa-facebook" />
                        </a>
                        <a href="https://in.pinterest.com/">
                            <i className="fa-brands fa-pinterest-p" />
                        </a>
                        <a href="https://www.instagram.com/">
                            <i className="fa-brands fa-instagram" />
                        </a>
                    </div>
                   
                    </div>
                   
                </div>
                
                </div>
                
            </div>
            
            </div>
            
        </div>

    </>
  )
}

export default First