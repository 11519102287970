import React from 'react';
import { Link } from 'react-router-dom';

function Academy() {
    return (
      <>
          <section className="department-details-section">
              <div className="container">
                <div className="row">
                  
                  <div className="col-12 col-lg-4 col-xl-4">
                    <div className="sidebar">
                      <div className="sidebar-widget-list-inner">
                        <ul>
                          <li>
                            <Link to="/communityservice">
                              Community Services
                              <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/gender">
                              Gender Unit
                              <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                          </li>
                          {/* <li>
                            <Link to="/services">
                              Internal Affairs
                              <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/communityservice">
                             Community Relations
                              <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                          </li> */}
                          <li>
                            <Link to="/standards">
                              Professional Standard
                              <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/protectionsection">
                              Women &amp; Children Protection Section
                              <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                          </li>
                        </ul>
                        
                      </div>
                      
                      <div className="sidebar-widget sidebar-widget-card">
                        <div className="sidebar-widget-card-icon">
                          <i className="flaticon-question" />
                        </div>
                        
                        <div className="sidebar-widget-card-content">
                          <h3>
                            <Link to="/contact">Get Any Help?</Link>
                          </h3>
                          <p>
                            Learn more about how police are recuited, trained and 
                            opportunities available for any Liberia citizens who wants 
                            to join the national police force.

                          </p>
                        </div>
                        
                      </div>
                      
                      {/* <div className="sidebar-widget">
                        <div className="sidebar-widget-box-icon">
                          <i className="flaticon-pdf" />
                        </div>
                        
                        <div className="sidebar-widget-box-content">
                          <h3>
                            Company briefing update of the <br /> 2022 year
                          </h3>
                          <Link to="/departmentdetails" className="btn btn-primary">
                            Download
                          </Link>
                        </div>
                        
                      </div> */}
                      
                    </div>
                    
                  </div>
                  
                  <div className="col-lg-8">
                    <div className="department-details-imgbox">
                      <img src={"assets/images/department/academy-1.jpg"} className="img-fluid" alt="img-159" />
                      <Link to="#" />
                      <div className="department-details-img-icon">
                        <i className="flaticon-police-badge-1" />
                      </div>
                    
                    </div>
                    
                    <div className="department-details-content-box">
                      <h4 className="department-details-title">The Liberia Police Academy</h4>
                      <p>
                      The Liberia police academy serves as the crucible where aspiring law enforcement officers undergo rigorous 
                      training to prepare them for the demanding challenges of protecting and serving their communities. 
                      From physical conditioning to intensive classroom instruction, the academy curriculum is designed 
                      to cultivate both the practical skills and the ethical principles essential for effective policing. 
                      Trainees engage in simulated scenarios that mimic real-world situations, honing their ability to 
                      make split-second decisions under pressure while adhering to legal protocols. They learn about 
                      criminal law, investigation techniques, and conflict resolution strategies, equipping them with 
                      the knowledge necessary to enforce laws fairly and maintain public order with professionalism and integrity.
                      </p>
                    </div>
                    
                    <div className="department-details-box">
                      <div className="department-details-policy">
                        <span>
                        The Liberia police academy provide thorough instruction in various aspects of law enforcement, including legal 
                        procedures, investigative techniques, and crisis management, ensuring officers are prepared to handle 
                        diverse situations effectively. Recruits undergo rigorous ethical training that emphasizes integrity, accountability, 
                        and respect for human rights, fostering a culture of professional conduct and adherence to legal standards.
                        </span>
                       
                      </div>
                     
                      <p>
                      Beyond the technical skills, the police academy also instills a sense of duty and commitment to public service in its recruits.
                      Through teamwork exercises and community-oriented initiatives, trainees develop an understanding of 
                      the role they play in fostering trust and cooperation within the neighborhoods they will serve. 
                      Graduation from the academy marks not just the completion of training but the beginning of a lifelong 
                      dedication to upholding justice and protecting the rights of all citizens. The rigorous standards and 
                      comprehensive education provided by the police academy ensure that law enforcement officers are 
                      well-prepared to handle the complexities and challenges of modern policing in a manner that respects 
                      both the law and the communities they serve.
                      </p>
                    </div>
                    
                    <div className="department-details-benefits-inner-box">
                      <div className="row row-gutter-30">
                        <div className="col-lg-6">
                          <div className="department-details-benefits-box-image">
                            <img src={"assets/images/department/academy-2.jpg"} className="img-fluid" alt={160} />
                          </div>
                          
                        </div>
                        
                        <div className="col-lg-6">
                          <div className="department-details-benefits-box">
                            <h3>Benefits of getting trained at the Police Academy</h3>
                            <p>
                            Empowering Law Enforcement: The Vital Benefits of Police Academy Training.
                            </p>
                            <ul className="list-unstyled list-style">
                              <li>
                                <i className="fa-solid fa-circle-arrow-right" />
                                <h5>Professional Skills</h5>
                              </li>
                              
                              <li>
                                <i className="fa-solid fa-circle-arrow-right" />
                                <h5>Ethical Standards</h5>
                              </li>
                              
                              <li>
                                <i className="fa-solid fa-circle-arrow-right" />
                                <h5>Community Engagement</h5>
                              </li>
                              
                            </ul>
                            
                          </div>
                          
                        </div>
                        
                      </div>
                      
                    </div>
                    
                    {/* <div className="department-details-law-box">
                      <h4>Europeon Government Law:</h4>
                      <p>
                        Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse
                        quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat
                        quo voluptas nulla pariatur iste natus error sit voluptatem
                        accusantium totam rem aperiam, eaque ipsa quae.
                      </p>
                    </div> */}
                    
                  </div>
                 
                </div>
              </div>
          </section>

          <section className="team-progress-section">
            <div className="container">
                <h3>Police Professional Skills</h3>
                <div className="row row-gutter-y-30">
                <div className="col-lg-6">
                    <div className="team-progressbar-text">Crisis Management</div>
                    <div className="team-progressbar">
                        <div className="team-progressbar-inner count-box" data-percentage="90%" style={{width: "90%"}}>
                            <div className="team-progressbar-inner-text count-text ninty">90%</div>
                        </div>
                    </div>
                </div>
               
                <div className="col-lg-6">
                    <div className="team-progressbar-text">Firearm Control</div>
                    
                    <div className="team-progressbar">
                        <div className="team-progressbar-inner count-box" data-percentage="50%" style={{width: "50%"}} >
                            <div className="team-progressbar-inner-text count-text">50%</div>
                        </div>
                    </div>
                </div>
               
                <div className="col-lg-6">
                    <div className="team-progressbar-text">Public Safety</div>
                    
                    <div className="team-progressbar">
                        <div className="team-progressbar-inner count-box" data-percentage="46%" style={{width: "46%"}}>
                            <div className="team-progressbar-inner-text count-text">46%</div>
                        </div>
                    </div> 
                </div>
               
                <div className="col-lg-6">
                    <div className="team-progressbar-text">Community Security</div>
                    
                    <div className="team-progressbar">
                        <div className="team-progressbar-inner count-box" data-percentage="60%" style={{width: "60%"}}>
                            <div className="team-progressbar-inner-text count-text">60%</div>
                        </div>
                    </div>
                </div>
                </div>
               
            </div>
        </section>

        <section className="team-details-contact">
            <div className="container">
                <div className="row justify-content-md-center">
                <div className="align-self-center col-lg-8">
                    <div className="section-title-box text-center">
                    <div className="section-tagline">Message us for training opportunity</div>
                    
                    <h2 className="section-title">Contact Us</h2>
                    </div>
                    
                </div>
                
                <div className="align-self-center col-lg-8">
                    <form action="assets/inc/sendemail.php" className="contact-form-one contact-form-validated" >
                    <div className="row row-gutter-10">
                        <div className="col-12 col-lg-6">
                        <input type="text" id="name" className="input-text" placeholder="Your name" name="name" aria-required="true" />
                        </div>
                        
                        <div className="col-12 col-lg-6">
                        <input type="email" id="email" className="input-text" placeholder="Email address" name="email" aria-required="true" />
                        </div>
                        
                        <div className="col-12 col-lg-6">
                        <input type="text" id="phone" className="input-text" placeholder="Phone number" name="phone" aria-required="true" />
                        </div>
                        
                        <div className="col-12 col-lg-6">
                        <input type="text" id="subject" className="input-text" placeholder="Subject" name="subject" aria-required="true" />
                        </div>
                        
                        <div className="col-12 col-lg-12">
                        <textarea name="message" placeholder="Write a message" className="input-text" aria-required="true" defaultValue={""} />
                        </div>
                       
                        <div className="col-12 col-lg-12 text-center">
                        <button className="btn btn-primary">Send a Message</button>
                        </div>
                       
                    </div>
                    
                    </form>
                    
                </div>
                
                </div>
                
            </div>
        </section>
      </>
    )
}

export default Academy
