import React, { useEffect } from 'react'
import Bredcom from '../../Bredcom/Main'
import Header from '../../headerone/Main'
import Blog from '../../menhome/Blog'
import Newlast from '../../menhome/Newlast'
import Statistics from '../mendataandstatistics/Statistics'


function Main() {

  useEffect(() => {
    document.getElementById("title").innerHTML="Data and Statistics";
  }, [])

  return (
    <>
        <Header/>

        <div className="page-wrapper">
        <Bredcom 
            subtitle="Home"
            title="data and statistics"
            subtitledown="Data and Statistics"
           />
            <Statistics/>
            <Blog/>
            <Newlast/>
        </div>
    </>
  )
}

export default Main