import React, { useState } from 'react';


function Questions() {

  const [Faq, setFaq] = useState(0);

  const Drop = [
    {
        Que: "What is the role of the Emergency Response Unit?",
        Answer: "The ERU like the PSU is one of the LNP units that carries weapons."
    },
    {
        Que: "Can I apply for a police clearance online?",
        Answer: "Yes, please."
    },
    {
        Que: "Can I report a crime online and remain anonymous?",
        Answer: " Yes, please. "
    }
]

  return (
    <>
        <section className="department-details-section">
            <div className="container">
              <div className="row">
 
                <div className="col-lg-8">
                <div className="news-details-content-box">

                    <h5>Frequently Asked Questions </h5>
                </div>

                <div className="accordian-box">
                      { Drop.map((items, index) => (
                          <div className={`accordian-box-item ${Faq !== index && "active"}`}  key={items}>
                              <div className="accordian-title">
                              <h5>{items.Que}</h5>
                                <i className="fa-solid fa-angle-right" onClick={ ()=> {setFaq(index)}} />
                              </div>

                              {Faq === index &&
                                <div className="accordian-content" key={items} style={{display: "block"}}>
                                  <p> {items.Answer} </p>
                                </div>
                              }
                          </div>
                        )) }
                  </div>
                  
                  
                </div>

              </div>
            </div>
        </section>
    </>
  )
}

export default Questions
