import React, { useEffect } from 'react';
import Bredcom from '../../Bredcom/Main';
import Header from '../../headerone/Main';
import Academy from '../menpoliceacademy/Academy';



function Main() {

  useEffect(() => {
    document.getElementById("title").innerHTML="Police Academy";
  }, [])

  return (
    <>
         <Header/>

         <div className="page-wrapper">
          <Bredcom 
            subtitle="Home"
            title="police academy"
            subtitledown="Police Academy"
           />
            <Academy/>
         </div>
    </>
  )
}

export default Main