import React, { useEffect } from 'react'
import Bredcom from '../../Bredcom/Main'
import Header from '../../headerone/Main'
import Blog from '../../menhome/Blog'
import Newlast from '../../menhome/Newlast'
import Mission from '../menmissionandvision/Mission'



function Main() {

  useEffect(() => {
    document.getElementById("title").innerHTML="Mission-and-vision";
  }, [])

  return (
    <>
        <Header/>

        <div className="page-wrapper">
          <Bredcom
           subtitle="Home"
           title="Mission and Vision"
           subtitledown="Mission and Vision"
           />
          <Mission/>
          {/* <Event/> */}
          <Blog/>
          <Newlast/>
        </div>
        
    </>
  )
}

export default Main