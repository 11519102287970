import React, { useEffect } from 'react';
import Bredcom from '../../Bredcom/Main';
import Header from '../../headerone/Main';
import Blog from '../../menhome/Blog';
import Newlast from '../../menhome/Newlast';
import Community from '../mencommunityservice/Community';



function Main() {

  useEffect(() => {
    document.getElementById("title").innerHTML="Community Service";
  }, [])

  return (
    <>
         <Header/>

         <div className="page-wrapper">
          <Bredcom 
            subtitle="Home"
            title="community service"
            subtitledown="Community Service"
           />
            <Community/>
            <Blog/>
            <Newlast/>
         </div>
    </>
  )
}

export default Main