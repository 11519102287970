import React from 'react'
import { Link } from 'react-router-dom'
// import Deatils from '../../../assets/image/services/service-details-1.jpg'

function StrategicP() {
  return (
    <>
        <section className="service-details-section">
            <div className="container">
                <div className="row">
                <div className="col-lg-8">
                    <div className="service-details-image">
                    <img src={"assets/images/services/strategic-p-1.jpg"} className="img-fluid" alt="img-146" />
                    </div>
                    
                    <div className="service-details-content-box">
                    <h3 className="service-details-title">Strategic Plan 2020 - 2025</h3>
                    <p>
                     The Police Department's Strategic Plan embodies our steadfast commitment to safeguarding our 
                     community and upholding the principles of justice and equity. Our overarching goal is to build 
                     a safer and more resilient city through proactive policing strategies that prioritize crime prevention, 
                     collaboration with stakeholders, and responsive service delivery. This plan articulates our vision for 
                     fostering trust and engagement with the community, ensuring that our actions are guided by transparency, 
                     accountability, and respect for human rights.
                    </p>
                    <h4>
                    Central to our strategic approach is the integration of innovative technologies and data-driven 
                    decision-making to enhance operational effectiveness and resource allocation. 
                    </h4>
                    <p>
                    By harnessing the power of analytics and community partnerships, we aim to address emerging challenges such 
                    as cybercrime, substance abuse, and youth violence. Through continuous training and professional development, 
                    we empower our officers to adapt to evolving threats and uphold the highest standards of professionalism. 
                    This strategic framework not only outlines our objectives for the coming years but also reaffirms our commitment 
                    to serving with integrity and compassion, ensuring a safer and more inclusive environment for all residents.
                    </p>
                    </div>
                   
                    {/* <h2 className="service-details-title">Goverment Jobs Fecilities:</h2>
                    <ul className="list-unstyled list-style-two">
                    <li>
                        <i className="fa-solid fa-circle-arrow-right" />
                        <h5>
                        Every pleasure is to be welcomed &amp; every pain get avoided.
                        </h5>
                    </li>
                    
                    <li>
                        <i className="fa-solid fa-circle-arrow-right" />
                        <h5>
                        Laborious physical exercise, except to obtain some advantage from.
                        </h5>
                    </li>
                    
                    <li>
                        <i className="fa-solid fa-circle-arrow-right" />
                        <h5>Quisque tincidunt eros ac place viverra simply free text.</h5>
                    </li>
                    
                    </ul> */}
                    
                    {/* <div className="service-details-box-inner">
                    <div className="row g-0">
                        <div className="col-lg-6">
                        <div className="service-details-box">
                            <div className="service-details-icon">
                            <Link to="/servicedetails">
                                <i className="flaticon-team" />
                            </Link>
                            </div>
                            
                            <div className="service-details-content">
                            <h4>
                                <Link to="/servicedetails">Proin Tempus</Link>
                            </h4>
                            <p>
                                There are many of of lorem Ipsum, but the majori have
                                suffered alteration in some form.
                            </p>
                            </div>
                            
                        </div>
                        
                        </div>
                        
                        <div className="col-lg-6">
                        <div className="service-details-box">
                            <div className="service-details-icon">
                            <Link to="/servicedetails">
                                <i className="flaticon-art" />
                            </Link>
                            </div>
                            
                            <div className="service-details-content">
                            <h4>
                                <Link to="/servicedetails">Proin Tempus</Link>
                            </h4>
                            <p>
                                There are many of of lorem Ipsum, but the majori have
                                suffered alteration in some form.
                            </p>
                            </div>
                            
                        </div>
                        
                        </div>
                        
                    </div>
                    
                    </div> */}
                    
                    {/* <p>
                    There are many variations of passages of lorem ipsum is simply free
                    text available in the market, but the majority time you put aside to
                    be in our office. Lorem ipsum dolor sit amet, consectetLorem ipsum
                    dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit
                    amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                    ut labore et dolore magna aliqua.
                    </p> */}
                </div>
                
                <div className="col-12 col-lg-4 col-xl-4">
                    <div className="sidebar">

                    <div className="sidebar-widget">
                        <div className="sidebar-widget-box-icon">
                        <i className="flaticon-pdf" />
                        </div>
                        
                        <div className="sidebar-widget-box-content">
                        <h3>
                           Get the LNP Strategic Plan <br /> 2020 - 2025
                        </h3>
                        <Link to="https://drive.google.com/file/d/1ix3iEY57D5nJ3XvGU7l6P6cFMfVF_XAY/view?usp=sharing" className="btn btn-primary">Download</Link>
                        </div>
                        
                    </div>

                    <div className="sidebar-widget sidebar-widget-card">
                        <div className="sidebar-widget-card-icon">
                        <i className="flaticon-question" />
                        </div>
                        
                        <div className="sidebar-widget-card-content">
                        <h3>
                            <Link to="/contact">Need Any Help?</Link>
                        </h3>
                        <p>
                        There are other useful resources available here. Use the link below 
                        to navigate to other resources and important information.
                        </p>
                        </div>
                        
                    </div>

                    <div className="sidebar-widget-list-inner">
                        <ul>
                        <li>
                            <Link to="https://drive.google.com/file/d/1KVjR_emn6JnUGsGovnUWNhvBmnOO94vS/view?usp=drive_link">
                              Police Act (2016)
                            <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                        </li>
                        <li>
                            <Link to="https://drive.google.com/file/d/14qleF_TIFouLxyLs1hCpUBfakz2Uuz6-/view?usp=drive_link">
                              Trafficking in Persons Handbook{" "}
                            <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                        </li>
                        <li>
                            <Link to="https://drive.google.com/file/d/1bRXZMScCfanjo22Yk_ytDgXq1sx-ilIx/view?usp=drive_link">
                             Revised Act to Ban Trafficking (2021)
                            <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                        </li>
                        <li>
                            <Link to="/clearance">
                             Police Clearance
                            <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                        </li>
                        <li>
                            <Link to="https://drive.google.com/file/d/1XCQ56uR5WfNcpNRn3JgEUFzslxygSkeZ/view?usp=drive_link">
                            Annual Reports
                            <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                        </li>
                        <li>
                            <Link to="https://drive.google.com/file/d/1OQ06j4CkaF2orOCXYAWePt1GVqKIMc6P/view?usp=drive_link">
                             Freedom of Information
                            <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                        </li>
                        </ul>
                        
                    </div>
                   
                    {/* <div className="sidebar-widget sidebar-widget-card">
                        <div className="sidebar-widget-card-icon">
                        <i className="flaticon-question" />
                        </div>
                        
                        <div className="sidebar-widget-card-content">
                        <h3>
                            <Link to="/contact">Get Any Help?</Link>
                        </h3>
                        <p>
                            There are many variations of passages of lorem ipsum is simply
                            free text available in the marke.
                        </p>
                        </div>
                        
                    </div> */}
                    
                    {/* <div className="sidebar-widget">
                        <div className="sidebar-widget-box-icon">
                        <i className="flaticon-pdf" />
                        </div>
                        
                        <div className="sidebar-widget-box-content">
                        <h3>
                            Company briefing update of the <br /> 2022 year
                        </h3>
                        <Link className="btn btn-primary">Download</Link>
                        </div>
                        
                    </div> */}
                    
                    </div>
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default StrategicP