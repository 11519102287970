import React from 'react'
import { Link } from 'react-router-dom'
// import Deatils from '../../../assets/image/services/service-details-1.jpg'

function Clearance() {
  return (
    <>
        <section className="service-details-section">
            <div className="container">
                <div className="row">
                <div className="col-lg-8">
                    <div className="service-details-image">
                    <img src={"assets/images/services/clearance-1.jpg"} className="img-fluid" alt="img-146" />
                    </div>
                    
                    <div className="service-details-content-box">
                    <h3 className="service-details-title">Police Clearance</h3>
                    <p>
                    A Police Clearance Certificate (PCC) is an official document issued by police or government authorities to 
                    confirm that an individual has no criminal record. This certificate is often required for various purposes, 
                    including employment, immigration, visa applications, or even adoption processes. It serves as proof of 
                    good conduct and is a vital component in ensuring the safety and security of communities and organizations 
                    that rely on the integrity of those they engage with. Obtaining a PCC typically involves submitting a formal 
                    application to the relevant police or governmental department. The application process may vary depending on 
                    the country or jurisdiction, but it generally includes providing personal identification details, 
                    fingerprinting, and a background check. Some regions may require additional documentation, such as proof of 
                    residence or a letter of request from the entity requiring the certificate. The processing time can also differ, 
                    ranging from a few days to several weeks, depending on the thoroughness of the checks and the efficiency of the issuing authority.
                    </p>
                    <h4>
                    The importance of a Police Clearance Certificate cannot be overstated. For individuals, it opens doors to opportunities 
                    in employment and international travel, ensuring that they are seen as trustworthy and law-abiding.
                    </h4>
                    <p>
                    The significance of a Police Clearance Certificate extends beyond merely fulfilling a formal requirement. For individuals, 
                    it is a crucial step towards achieving various personal and professional milestones, such as securing a job, traveling abroad, 
                    or joining educational institutions. For organizations and governmental bodies, the PCC is a vital tool in maintaining safety 
                    and integrity by ensuring that individuals with a history of criminal activity are appropriately vetted. By promoting 
                    transparency and trust, the PCC plays a key role in enhancing public safety and fostering a sense of security within communities.
                    </p>
                    </div>
                   
                    {/* <h2 className="service-details-title">Goverment Jobs Fecilities:</h2>
                    <ul className="list-unstyled list-style-two">
                    <li>
                        <i className="fa-solid fa-circle-arrow-right" />
                        <h5>
                        Every pleasure is to be welcomed &amp; every pain get avoided.
                        </h5>
                    </li>
                    
                    <li>
                        <i className="fa-solid fa-circle-arrow-right" />
                        <h5>
                        Laborious physical exercise, except to obtain some advantage from.
                        </h5>
                    </li>
                    
                    <li>
                        <i className="fa-solid fa-circle-arrow-right" />
                        <h5>Quisque tincidunt eros ac place viverra simply free text.</h5>
                    </li>
                    
                    </ul> */}
                    
                    {/* <div className="service-details-box-inner">
                    <div className="row g-0">
                        <div className="col-lg-6">
                        <div className="service-details-box">
                            <div className="service-details-icon">
                            <Link to="/servicedetails">
                                <i className="flaticon-team" />
                            </Link>
                            </div>
                            
                            <div className="service-details-content">
                            <h4>
                                <Link to="/servicedetails">Proin Tempus</Link>
                            </h4>
                            <p>
                                There are many of of lorem Ipsum, but the majori have
                                suffered alteration in some form.
                            </p>
                            </div>
                            
                        </div>
                        
                        </div>
                        
                        <div className="col-lg-6">
                        <div className="service-details-box">
                            <div className="service-details-icon">
                            <Link to="/servicedetails">
                                <i className="flaticon-art" />
                            </Link>
                            </div>
                            
                            <div className="service-details-content">
                            <h4>
                                <Link to="/servicedetails">Proin Tempus</Link>
                            </h4>
                            <p>
                                There are many of of lorem Ipsum, but the majori have
                                suffered alteration in some form.
                            </p>
                            </div>
                            
                        </div>
                        
                        </div>
                        
                    </div>
                    
                    </div> */}
                    
                    {/* <p>
                    There are many variations of passages of lorem ipsum is simply free
                    text available in the market, but the majority time you put aside to
                    be in our office. Lorem ipsum dolor sit amet, consectetLorem ipsum
                    dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit
                    amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                    ut labore et dolore magna aliqua.
                    </p> */}
                </div>
                
                <div className="col-12 col-lg-4 col-xl-4">
                    <div className="sidebar">

                    <div className="sidebar-widget">
                        <div className="sidebar-widget-box-icon">
                        <i className="flaticon-pdf" />
                        </div>
                        
                        <div className="sidebar-widget-box-content">
                        <h3>
                           Get a copy of police clearance <br /> form
                        </h3>
                        <Link to="https://drive.google.com/file/d/1yFfk-jEoIsfIrL1xxtGNfDNHyCwRGx0b/view?usp=drive_link" className="btn btn-primary">Download</Link>
                        </div>
                        
                    </div>

                    <div className="sidebar-widget sidebar-widget-card">
                        <div className="sidebar-widget-card-icon">
                        <i className="flaticon-question" />
                        </div>
                        
                        <div className="sidebar-widget-card-content">
                        <h3>
                            <Link to="/contact">Need Any Help?</Link>
                        </h3>
                        <p>
                        There are other useful resources available here. Use the link below 
                        to navigate to other resources and important information.
                        </p>
                        </div>
                        
                    </div>

                    <div className="sidebar-widget-list-inner">
                        <ul>
                        <li>
                            <Link to="https://drive.google.com/file/d/1KVjR_emn6JnUGsGovnUWNhvBmnOO94vS/view?usp=drive_link">
                              Police Act (2016)
                            <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                        </li>
                        <li>
                            <Link to="https://drive.google.com/file/d/14qleF_TIFouLxyLs1hCpUBfakz2Uuz6-/view?usp=drive_link">
                              Trafficking in Persons Handbook{" "}
                            <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                        </li>
                        <li>
                            <Link to="https://drive.google.com/file/d/1bRXZMScCfanjo22Yk_ytDgXq1sx-ilIx/view?usp=drive_link">
                             Revised Act to Ban Trafficking (2021)
                            <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                        </li>
                        <li>
                            <Link to="/clearance">
                             Police Clearance
                            <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                        </li>
                        <li>
                            <Link to="https://drive.google.com/file/d/1XCQ56uR5WfNcpNRn3JgEUFzslxygSkeZ/view?usp=drive_link">
                            Annual Reports
                            <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                        </li>
                        <li>
                            <Link to="https://drive.google.com/file/d/1OQ06j4CkaF2orOCXYAWePt1GVqKIMc6P/view?usp=drive_link">
                             Freedom of Information
                            <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                        </li>
                        </ul>
                        
                    </div>
                   
                    {/* <div className="sidebar-widget sidebar-widget-card">
                        <div className="sidebar-widget-card-icon">
                        <i className="flaticon-question" />
                        </div>
                        
                        <div className="sidebar-widget-card-content">
                        <h3>
                            <Link to="/contact">Get Any Help?</Link>
                        </h3>
                        <p>
                            There are many variations of passages of lorem ipsum is simply
                            free text available in the marke.
                        </p>
                        </div>
                        
                    </div> */}
                    
                    {/* <div className="sidebar-widget">
                        <div className="sidebar-widget-box-icon">
                        <i className="flaticon-pdf" />
                        </div>
                        
                        <div className="sidebar-widget-box-content">
                        <h3>
                            Company briefing update of the <br /> 2022 year
                        </h3>
                        <Link className="btn btn-primary">Download</Link>
                        </div>
                        
                    </div> */}
                    
                    </div>
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Clearance