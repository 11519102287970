import React, { useEffect } from 'react'
import Bredcom from '../../Bredcom/Main'
import Header from '../../headerone/Main'
import Blog from '../../menhome/Blog'
import Newlast from '../../menhome/Newlast'
import History from '../menlnphistory/History'



function Main() {

  useEffect(() => {
    document.getElementById("title").innerHTML="Lnp-history";
  }, [])

  return (
    <>
        <Header/>

        <div className="page-wrapper">
          <Bredcom
           subtitle="Home"
           title="LNP History"
           subtitledown="LNP History"
           />
          <History/>
          <Blog/>
          <Newlast/>
        </div>
        
    </>
  )
}

export default Main