import React from 'react';
import { Link } from 'react-router-dom';

function Academy() {
    return (
      <>
          <section className="department-details-section">
              <div className="container">
                <div className="row">
                  
                  <div className="col-12 col-lg-4 col-xl-4">
                    <div className="sidebar">
                      
                    <div className="sidebar-widget-list-inner">
                        <ul>
                          <li>
                            <Link to="/communityservice">
                              Community Services
                              <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/gender">
                              Gender Unit
                              <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                          </li>
                          {/* <li>
                            <Link to="/services">
                              Internal Affairs
                              <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/communityservice">
                             Community Relations
                              <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                          </li> */}
                          <li>
                            <Link to="/standards">
                              Professional Standard
                              <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                          </li>
                          <li>
                            <Link to="/policeacademy">
                              Police Academy
                              <i className="fa-solid fa-arrow-right-long" />
                            </Link>
                          </li>
                        </ul>
                        
                      </div>
                      
                      <div className="sidebar-widget sidebar-widget-card">
                        <div className="sidebar-widget-card-icon">
                          <i className="flaticon-question" />
                        </div>
                        
                        <div className="sidebar-widget-card-content">
                          <h3>
                            <Link to="/contact">Get Any Help?</Link>
                          </h3>
                          <p>
                            Learn more about how to REPORT all cases relating 
                            to voilations of women, girls, men and boys to the 
                            women and children protection section.

                          </p>
                        </div>
                        
                      </div>
                      
                      <div className="sidebar-widget">
                        <div className="sidebar-widget-box-icon">
                          <i className="flaticon-pdf" />
                        </div>
                        
                        <div className="sidebar-widget-box-content">
                          <h3>
                            Do you notice anything and want to report it to WACPS?
                          </h3>
                          <Link to="#" className="btn btn-primary">
                            Download
                          </Link>
                        </div>
                        
                      </div>
                      
                    </div>
                    
                  </div>
                  
                  <div className="col-lg-8">
                    <div className="department-details-imgbox">
                      <img src={"assets/images/department/protection-1.jpg"} className="img-fluid" alt="img-159" />
                      <Link to="#" />
                      <div className="department-details-img-icon">
                        <i className="flaticon-police-badge-1" />
                      </div>
                    
                    </div>
                    
                    <div className="department-details-content-box">
                      <h4 className="department-details-title">Women And Children Protection Section</h4>
                      <p>
                      In 2005, the Liberia National Police established a Specialized Section, the Women And Children Protection Section (WACPS), 
                      to assist in gathering data and investigating cases of violence against women and children. The section has made significant 
                      strides that have immensely contributed in improving the rate of response and prevention of all forms of violence against 
                      women, girls, men and boys in Liberia.
                      Women And Children Protection Section (WACPS), is responsible for raising awareness on all forms of crimes and violence against 
                      women, girls, men and boys, including SGBV. WACPS investigates reports of crimes by gathering physical evidence and interviewing 
                      witnesses, suspects and all concerned persons in order to determine whether the crimes were committed.
                      </p>
                    </div>
                    
                    <div className="department-details-box">
                      <div className="department-details-policy">
                        <span>
                        The Women and Children Protection Section of the Liberia national police is dedicated to addressing and mitigating issues related to the safety 
                        and well-being of women and children. This specialized unit focuses on preventing and responding to crimes such as domestic 
                        violence, sexual assault, child abuse, and human trafficking.
                        </span>
                       
                      </div>
                     
                      {/* <p>
                      Responsibilities of the Women And Children Protection Section (WACPS) <br/>
                        a.	Ensure appropriate protection and assistance to survivors and/or witnesses including prompt medical treatment;<br/>
                        b.	Provide services to both women and child victims, as well as women and child alleged offenders, those who are in conflict with the law;<br/>
                        c.	Thoroughly investigate all forms of violence against women and girls including SGBV cases, domestic violence, child abuse and human trafficking;<br/>
                        d.	Coordinate with other service providers for effective prosecution of perpetrators;<br/>
                        e.	Provide evidence and monitor court proceedings;<br/>
                        f.	Raise awareness on SGBV cases in collaboration with the Community Services Section of LNP, MoGCSP, SGBV- CU and other institutions within communities;<br/>
                        g.	Undertake preventive measures to reduce the occurrence of crimes and all forms of violence against women and children;<br/>
                        h.	Accurately record, manage database and information on all crimes reported to WACPS;<br/>

                      </p> */}
                    </div>
                    <div className="department-details-imgbox">
                      <img src={"assets/images/department/protection-1.jpg"} className="img-fluid" alt="img-159" />
                      <Link to="#" />
                    </div>
                      
                    <div className="department-details-content-box">
                      <h4 className="department-details-title"></h4>
                      <p>
                      Mandates of Women And Children Protection Section (WACPS) WACPS provides services where the dignity and rights of 
                      vulnerable women and children (male or female) are protected and ensures that they are not subject to secondary victimization. <br/>
                            a.	Thoroughly investigate all reported cases of violence against women and children;<br/>
                            b.	Gather and provide substantial evidence on all forms of reported crimes against women and girls for prosecution;<br/>
                            c.	Provide psychological support to survivors of violence including SGBV;<br/>
                            d.  Provide safety and security for survivors and witnesses;<br/>
                            e.  Provide information and advice regarding investigation procedures;<br/> 
                            f.  Refer survivors to One Stop Centers (OSC) or other medical facilities for   treatment and examination;<br/>
                      </p>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>

                    </div>
                 
                  </div>
                 
                </div>
              </div>
          </section>

      </>
    )
}

export default Academy
