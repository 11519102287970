import React, { useEffect } from 'react'
import Bredcom from '../../Bredcom/Main'
import Header from '../../headerone/Main'
import Client from '../../menhome/Client'
import Team from '../../menhometwo/Team'
import Aboutone from '../menabout/Aboutone'
import Last from '../menabout/Last'
import Testimonial from '../menabout/Testimonial'


function Main() {

  useEffect(() => {
    document.getElementById("title").innerHTML="About";
  }, [])

  return (
    <>
        <Header/>

        <div className="page-wrapper">
          <Bredcom
           subtitle="Home"
           title="About"
           subtitledown="About"
           />
          <Aboutone/>
          <Client/>
          <Testimonial/>
          <Team/>
          <Last/>
          
        </div>
        
    </>
  )
}

export default Main