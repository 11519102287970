import React, { useState } from 'react';
import { Autoplay, FreeMode, Navigation, Pagination, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";


function Testimonial() {

    const [thumbsSwiper, setThumbsSwiper] = useState(null);
      
  return (
    <>
        <section className="testimonial-section">
            <div className="container">
                <div className="testimonial-name"></div>

                <div className="testimonial-slider">

                    <Swiper className="swiper testimonial-reviews" 
                    spaceBetween={60} 
                    slidesPerView={1} 
                    loop={true} 
                    thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                    pagination={{
                        clickable: true,
                        el: ".swiper-pagination"
                      }}
                    speed={500} 
                    modules={[Autoplay, Navigation,Thumbs,FreeMode,Pagination ]} >
                        <div className="swiper-wrapper">
                            <SwiperSlide className="swiper-slide">
                                <div className="testimonial-content">
                                <div className="testimonial-ratings">
                                    <i className="fa-solid fa-star" />
                                    <i className="fa-solid fa-star" />
                                    <i className="fa-solid fa-star" />
                                    <i className="fa-solid fa-star" />
                                    <i className="fa-solid fa-star" />
                                </div>
                                
                                <div className="testimonial-text">
                                The Liberia National Police is actively engaging with local residents to build trust 
                                and ensure safety. Through community outreach programs and responsive 
                                policing, they aim to create a safer environment for everyone. By fostering 
                                partnerships and listening to concerns, they work towards a more secure and 
                                cohesive community..
                                </div>
                                
                                <div className="testimonial-thumb-card">
                                    <h5>Hon. William K. Mulbah </h5>
                                    <span>101 Office</span>
                                </div>
                                
                                </div>
                            </SwiperSlide>
                            
                            <SwiperSlide className="swiper-slide">
                                <div className="testimonial-content">
                                <div className="testimonial-ratings">
                                    <i className="fa-solid fa-star" />
                                    <i className="fa-solid fa-star" />
                                    <i className="fa-solid fa-star" />
                                    <i className="fa-solid fa-star" />
                                    <i className="fa-solid fa-star" />
                                </div>
                                
                                <div className="testimonial-text">
                                The Liberia National Police is committed to promoting gender equality through inclusive hiring practices 
                                and training programs. They actively address gender-based violence and discrimination to 
                                ensure equal protection under the law. By fostering a culture of respect and support, 
                                they strive to empower individuals of all genders within their communities.
                                </div>
                                
                                <div className="testimonial-thumb-card">
                                    <h5>Hon./Atty J. Nelson Freeman </h5>
                                    <span>102 Office</span>
                                </div>
                                
                                </div>
                            </SwiperSlide>
                            
                            <SwiperSlide className="swiper-slide">
                                <div className="testimonial-content">
                                <div className="testimonial-ratings">
                                    <i className="fa-solid fa-star" />
                                    <i className="fa-solid fa-star" />
                                    <i className="fa-solid fa-star" />
                                    <i className="fa-solid fa-star" />
                                    <i className="fa-solid fa-star" />
                                </div>
                                
                                <div className="testimonial-text">
                                The Liberia National Police prioritize protecting life and property through proactive patrolling and rapid 
                                response to emergencies. They employ strategic crime prevention measures and collaborate 
                                with communities to enhance safety. By enforcing laws and maintaining order, they aim to 
                                safeguard both individuals and their belongings effectively.
                                </div>
                                
                                <div className="testimonial-thumb-card">
                                    <h5>DCP Fitzgerald TM Biago </h5>
                                    <span>114 Office</span>
                                </div>
                                
                                </div>
                            </SwiperSlide>     
                        </div>
                    
                        <div className="swiper-pagination" />
                    </Swiper>
                    
                    <Swiper className="testimonial-thumb" 
                           slidesPerView={3} 
                           spaceBetween={0}
                           modules={[FreeMode, Navigation, Thumbs]}
                           onSwiper={setThumbsSwiper}
                           freeMode={true}
                           watchSlidesProgress={true} >
                        <div className="swiper-wrapper">
                            <SwiperSlide className="swiper-slide">
                                <img src={"assets/images/testimonial/testimonial-2.png"} className="img-fluid" alt="img-17" />
                                <i className="fa-solid fa-quote-left" />
                            </SwiperSlide>
                        
                            <SwiperSlide className="swiper-slide">
                                <img src={"assets/images/testimonial/testimonial-3.png"} className="img-fluid" alt="img-18" />
                                <i className="fa-solid fa-quote-left" />
                            </SwiperSlide>
                        
                            <SwiperSlide className="swiper-slide">
                                <img src={"assets/images/testimonial/testimonial-4.png"} className="img-fluid" alt="img-19" />
                                <i className="fa-solid fa-quote-left" />
                            </SwiperSlide>
                        </div>
                    </Swiper>
                </div>
                
            </div>
        </section>
    </>
  )
}

export default Testimonial